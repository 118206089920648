import React from "react";

export default function LoginForm(props) {
  return (
    <React.Fragment>
      <p className="login-box-msg">Sign in to start your session</p>
      <form onSubmit={props.handleFormSubmit}>
        <div className="input-group mb-3">
          <input
            className="form-control"
            type="text"
            name="username"
            value={props.data.username}
            onChange={props.handleInputChange}
            placeholder="Enter username"
          />
          <div className="input-group-append">
            <div className="input-group-text">
              <span className="fas fa-user" />
            </div>
          </div>
        </div>
        <div className="input-group mb-3">
          <input
            className="form-control"
            type="password"
            name="password"
            value={props.data.password}
            onChange={props.handleInputChange}
            placeholder="Enter password"
          />
          <div className="input-group-append">
            <div className="input-group-text">
              <span className="fas fa-lock" />
            </div>
          </div>
        </div>
        {props.data.errorMessage && (
          <span className="form-error">{props.data.errorMessage}</span>
        )}
        <div className="row">
          <div className="col-8">
            <div className="icheck-primary">
              <input type="checkbox" id="remember" />
              <label htmlFor="remember">Remember Me</label>
            </div>
          </div>
          {/* /.col */}
          <div className="col-4">
            <button
              className="btn btn-primary btn-block"
              disabled={props.data.isSubmitting}
            >
              {props.data.isSubmitting ? "Loading..." : "Login"}
            </button>
          </div>
          {/* /.col */}
        </div>
      </form>

      <p className="mb-1">
        <a href="forgot-password.html">I forgot my password</a>
      </p>
    </React.Fragment>
  );
}

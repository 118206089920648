import { useState, useEffect, useContext } from "react";
//import { API } from "../components/services/api-service";
import AuthContext from "../components/common/AuthContext";

export default function useFetch(url, method, options = {}) {
  const { authState } = useContext(AuthContext);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log("useFetch");
    const fetchData = async () => {
      setLoading(true);
      try {
        const token = authState.token.slice(1, -1);
        const res = await fetch(url, {
          method: method,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        });
        const json = await res.json();
        console.log("json", json);
        setResponse(json);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);
  return { response, error, loading };
}

export { useFetch };

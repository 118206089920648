import React, { useState, useEffect, useContext } from "react";
import { API } from "../common/api-service";
import { commonFunction } from "../common/common-functions";
import AuthContext from "../common/AuthContext";
import { useHistory, useLocation } from "react-router-dom";
import { WithContext as ReactTags } from "react-tag-input";
import MyQuill from "../common/MyQuill";

const KeyCodes = {
  comma: 188,
  enter: [10, 13],
};
const delimiters = [...KeyCodes.enter, KeyCodes.comma];

export default function CompanyForm() {
  const history = useHistory();
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState('');
  const initialState = {
    id: "",
    name: "",
    slug: "",
    location: null,
    website: "",
    mobile: "",
    description: "",
    otherinfos: "",
    active: false,
  };
  const [state, setState] = useState(initialState);
  const [tags, setTags] = useState([]);

  const { authState } = useContext(AuthContext);
  const token = authState.token.slice(1, -1);

  const [isLoading, setIsLoading] = useState(false);
  const customStyles = {
    control: (base, state) => ({
      ...base,
      height: 5,
    }),
    option: (base, state) => ({
      ...base,
      height: 35,
    }),
  };

  const [isChanged, setIsChanged] = useState(false);
  const isDisabled =
    state.name.length === 0 || state.slug.length === 0 || !isChanged;

  const handleChange = (evt) => {
    const value =
      evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
    setIsChanged(true);
  };

  const handleSlugChange = (evt) => {
    const slug = commonFunction.slugify(evt.target.value);
    setState({
      ...state,
      name: evt.target.value,
      slug: slug,
    });
    setIsChanged(true);
  };

  const handleDelete = (i) => {
    var tagslist = tags.filter((tag, index) => index !== i);
    setTags(tagslist);
    setState({ ...state, location: commonFunction.connectStr(tagslist) });
    setIsChanged(true);
  };

  const handleAddition = (tag) => {
    var tagslist = tags;
    setTags([...tags, tag]);
    tagslist.push(tag);
    setState({ ...state, location: commonFunction.connectStr(tagslist) });
    setIsChanged(true);
  };

  const createClicked = () => {
    API.createItem("companies", state, token)
      .then((resp) => {
        history.push("/jobs/");
      })
      .catch((error) => setErrorMessage(error.message));
  };

  const updateClicked = () => {
    if (location.state && isChanged) {
      API.updateItem("companies", location.state.id, state, token)
        .then((resp) => {
          history.push("/jobs/");
        })
        .catch((error) => console.log(error));
    }
  };

  const onCancel = () => {
    history.push("/jobs/");
  };

  useEffect(() => {
    if (location.state) {
      if (location.state.id) {
        API.getItem("companies", location.state.id, token)
          .then((resp) => {
            setState({
              ...state,
              name: resp.name,
              slug: resp.slug,
              location: resp.location,
              website: resp.website,
              mobile: resp.mobile,
              description: resp.description,
              otherinfos: resp.otherinfos,
              active: resp.active,
            });
            setTags(commonFunction.splitStrToTags(resp.location));
          })
          .catch((error) => console.log(error));
      }
    }
  }, []);

  return (
    <React.Fragment>
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Create Company</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Create Company</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          <div className="card card-default">
            <div className="card-header">
              <h3 className="card-title">New Company</h3>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Location</label>
                    <ReactTags
                      tags={tags}
                      delimiters={delimiters}
                      handleDelete={handleDelete}
                      handleAddition={handleAddition}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Name</label>
                    <input
                      id="name"
                      name="name"
                      className="form-control"
                      type="text"
                      placeholder="Enter name"
                      value={state.name}
                      onChange={handleSlugChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Website</label>
                    <input
                      id="website"
                      name="website"
                      className="form-control"
                      type="text"
                      placeholder="Enter website"
                      value={state.website}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Slug</label>
                    <input
                      id="slug"
                      name="slug"
                      className="form-control"
                      type="text"
                      placeholder="Enter slug"
                      value={state.slug}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Mobile</label>
                    <input
                      id="mobile"
                      name="mobile"
                      className="form-control"
                      type="number"
                      placeholder="Enter mobile"
                      value={state.mobile}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group clearfix">
                    <label>Is active?</label>
                    <div className="icheck-primary d-inline ustorekit-common-margin-10-side">
                      <input
                        type="checkbox"
                        checked={state.active}
                        id="active"
                        name="active"
                        onChange={handleChange}
                      />
                      <label htmlFor="active"></label>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Description</label>
                    <MyQuill
                      handleChange={(data) => {
                        setState({ ...state, description: data });
                        setIsChanged(true);
                      }}
                      data={state.description}
                      token={token}
                      id="description"
                      name="description"
                    ></MyQuill>
                    {/* <textarea
                      className="form-control"
                      id="description"
                      name="description"
                      cols="100"
                      rows="6"
                      defaultValue={state.description}
                      placeholder="Enter description"
                      onChange={handleChange}
                    ></textarea> */}
                    {/* <CKEditor
                      id="description"
                      name="description"
                      editor={ClassicEditor}
                      data={state.description}
                      onReady={(editor) => {
                        // You can store the "editor" and use when it is needed.
                        console.log("Editor is ready to use!", editor);
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setState({
                          ...state,
                          description: data,
                        });
                      }}
                    /> */}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Other Infomations</label>
                    <MyQuill
                      handleChange={(data) => {
                        setState({ ...state, otherinfos: data });
                        setIsChanged(true);
                      }}
                      data={state.otherinfos}
                      token={token}
                      id="otherinfos"
                      name="otherinfos"
                    ></MyQuill>
                    {/* <textarea
                      className="form-control"
                      id="otherinfos"
                      name="otherinfos"
                      cols="100"
                      rows="6"
                      defaultValue={state.otherinfos}
                      placeholder="Enter other informations"
                      onChange={handleChange}
                    ></textarea> */}
                    {/* <CKEditor
                      id="otherinfos"
                      name="otherinfos"
                      editor={ClassicEditor}
                      data={state.otherinfos}
                      onReady={(editor) => {
                        // You can store the "editor" and use when it is needed.
                        console.log("Editor is ready to use!", editor);
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setState({
                          ...state,
                          otherinfos: data,
                        });
                      }}
                    /> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer">
              {location.state && location.state.id ? (
                <button
                  className="btn btn-info"
                  onClick={updateClicked}
                  disabled={isDisabled}
                >
                  Update
                </button>
              ) : (
                <button
                  className="btn btn-info"
                  onClick={createClicked}
                  disabled={isDisabled}
                >
                  Create
                </button>
              )}
              <button
                className="btn btn-default float-right"
                onClick={() => {
                  if (
                    window.confirm("Are you sure you wish to delete this item?")
                  )
                    onCancel();
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
}

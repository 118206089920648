import React, { useState, useEffect, useContext } from "react";
import { API } from "../common/api-service";
import AuthContext from "../common/AuthContext";
import { useHistory, useLocation } from "react-router-dom";
import { commonFunction } from "../common/common-functions";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";

const floatRight = {
  float: "right",
};

export default function CompanyForm() {
  const history = useHistory();
  const location = useLocation();
  const initialState = {
    id: "",
    name: "",
    slug: "",
    location: "",
    website: "",
    mobile: "",
    description: "",
    otherinfos: "",
    active: false,
  };
  const [state, setState] = useState(initialState);
  const [jobs, setJobs] = useState([]);

  const { authState } = useContext(AuthContext);
  const token = authState.token.slice(1, -1);

  const [locationTags, setLocationTags] = useState([]);

  const displayLocationTags =
    locationTags &&
    locationTags.map((tag, i) => {
      return (
        <button
          className="btn btn-info btn-xs ustorekit-common-margin-10-right"
          key={tag.text}
        >
          {tag.text}
        </button>
      );
    });

  const deleteItem = () => {
    if (location.state) {
      if (location.state.id) {
        if (window.confirm("Are you sure you wish to delete this item?"))
          API.updateItem(
            "companies",
            location.state.id,
            { active: false },
            token
          )
            .then((resp) => {
              setState({ ...state, active: resp.active });
            })
            .catch((error) => console.log(error));
      }
    }
  };

  const onUpdate = () => {
    if (location.state) {
      if (location.state.id) {
        history.push({
          pathname: "/companies/create/",
          state: { id: location.state.id },
        });
      }
    }
  };

  useEffect(() => {
    if (location.state) {
      if (location.state.id) {
        API.getItem("companies", location.state.id, token)
          .then((resp) => {
            setState({
              ...state,
              id: resp.id,
              name: resp.name,
              slug: resp.slug,
              location: resp.location,
              website: resp.website,
              mobile: resp.mobile,
              description: resp.description,
              otherinfos: resp.otherinfos,
              active: resp.active,
            });
            setLocationTags(commonFunction.splitStrToTags(resp.location));

            var link = "jobs/company/" + resp.id.toString() + "/";
            API.getItems(link, token)
              .then((resp2) => {
                setJobs(resp2.jobs);
              })
              .catch((error) => console.log(error));
          })
          .catch((error) => console.log(error));
      }
    }
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Company Detail</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Company Detail</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          <div className="card">
            <div className="card-header">
              <div className="row">
                <div className="col-md-8">
                  <h3 className="card-title">
                    {state.id} - {state.name}
                  </h3>
                </div>
                <div className="col-md-4 text-center">
                  <button
                    className="btn btn-sm btn-primary ustorekit-common-margin-10-right"
                    onClick={onUpdate}
                  >
                    Update
                  </button>
                  <button
                    className="btn btn-sm btn-warning ustorekit-common-margin-10-right"
                    onClick={deleteItem}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-12 col-md-12 col-lg-8 order-2 order-md-1">
                  <div className="row">
                    <div className="col-12 col-sm-4">
                      <div className="info-box bg-light">
                        <div className="info-box-content">
                          <span className="info-box-text text-center text-muted">
                            Total jobs
                          </span>
                          <span className="info-box-number text-center text-muted mb-0">{jobs.length}</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-4">
                      <div className="info-box bg-light">
                        <div className="info-box-content">
                          <span className="info-box-text text-center text-muted">
                            Number of vacancies
                          </span>
                          <span className="info-box-number text-center text-muted mb-0"></span>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-4">
                      <div className="info-box bg-light">
                        <div className="info-box-content">
                          <span className="info-box-text text-center text-muted">
                            Reward
                          </span>
                          <span className="info-box-number text-center text-muted mb-0"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <h5>Jobs list</h5>
                      {jobs &&
                        jobs.map((job, i) => (
                          <div className="post" key={i}>
                            <div className="user-block">
                              <span className="username">
                                <Link
                                  to={{
                                    pathname: "/jobs/detail/",
                                    state: { id: job.id },
                                  }}
                                >
                                  {job.title}
                                </Link>
                              </span>
                              <span className="description">
                                Location: {job.location}
                              </span>
                            </div>
                            <p>Salary: {job.salary}</p>
                            <p>Number of vacancies: {job.numofvacancies}</p>
                            <p>Level: {job.level}</p>
                            <p>Reward: {job.reward}</p>
                            {/* <p>Description: {job.description.slice(0, 300) + "..."}</p> */}
                            <p>Description: {ReactHtmlParser(job.description)}</p>
                            <p>
                              <Link
                                to={{
                                  pathname: "/applicants/apply_job/",
                                  state: { id: job.id },
                                }}
                                className="link-black text-sm"
                              >
                                <i className="fas fa-link mr-1"></i>Find Candidates
                              </Link>
                            </p>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-12 col-lg-4 order-1 order-md-2">
                  <h3 className="text-primary">
                    <i className="fas fa-paint-brush"></i> {state.name}
                  </h3>
                  <p className="text-muted">{ReactHtmlParser(state.description)}</p>
                  <br />
                  <div className="text-muted">
                    <p className="text-sm">
                      <b className="d-block">Location</b>
                      {displayLocationTags}
                    </p>
                    <p className="text-sm">
                      <b className="d-block">Mobile</b>
                      {state.mobile}
                    </p>
                    <p className="text-sm">
                      <b className="d-block">Is active?</b>
                      {state.active ? "Yes" : "No"}
                    </p>
                    <p className="text-sm">
                      <b className="d-block">Other informations</b>
                      {ReactHtmlParser(state.otherinfos)}
                    </p>
                  </div>

                  <div className="text-center mt-5 mb-3">
                    <button
                      className="btn btn-sm btn-primary ustorekit-common-margin-10-right"
                      onClick={onUpdate}
                    >
                      Update
                    </button>
                    <button
                      className="btn btn-sm btn-warning ustorekit-common-margin-10-right"
                      onClick={deleteItem}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
}

import React, { useState, useEffect, useContext } from "react";
import { API } from "../common/api-service";
import { commonFunction } from "../common/common-functions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FilesUpload from "../common/FilesUpload";
import FileUploadService from "../common/FileUploadService";
import AuthContext from "../common/AuthContext";
import { useHistory, useLocation } from "react-router-dom";
import { WithContext as ReactTags } from "react-tag-input";
import Select from "react-select";
import MyQuill from "../common/MyQuill";

// For react-select
const KeyCodes = {
  comma: 188,
  enter: [10, 13],
};
const delimiters = [...KeyCodes.enter, KeyCodes.comma];

export default function CandidateForm() {
  const history = useHistory();
  const location = useLocation();
  const initialState = {
    id: "",
    name: "",
    location: null,
    gender: "",
    dob: "",
    current_position: "",
    mobile: "",
    email: "",
    address: "",
    social_link: "",
    tags: "",
    active: false,
    notes: "",
  };
  const [state, setState] = useState(initialState);
  const [locationTags, setLocationTags] = useState([]);
  const [tags, setTags] = useState([]);

  const genderList = [
    { value: "M", label: "Male" },
    { value: "F", label: "Female" },
  ];
  const [selectedGender, setSelectedGender] = useState();
  const getGenderLabel = (gender) => {
    if (gender === "M") {
      return "Male";
    } else {
      return "Female";
    }
  };

  // Files
  const [files, setFiles] = useState([]);
  const [savedFiles, setSavedFiles] = useState([]);
  const [deleteSavedFiles, setDeleteSavedFiles] = useState([]);
  const [progress, setProgress] = useState(0);
  const [isUpdating, setIsUpdating] = useState(false);

  const { authState } = useContext(AuthContext);
  const token = authState.token.slice(1, -1);

  const [isLoading, setIsLoading] = useState(false);
  const customStyles = {
    control: (base, state) => ({
      ...base,
      height: 5,
    }),
    option: (base, state) => ({
      ...base,
      height: 35,
    }),
  };

  const [isChanged, setIsChanged] = useState(false);
  const isDisabled = state.name.length === 0 || !isChanged;

  //   const [startDate, setStartDate] = useState(new Date());
  // Datepicker
  //   const [startDate, setStartDate] = useState(new Date());
  //   const years = range(1990, getYear(new Date()) + 1, 1);
  //   const months = [
  //     "January",
  //     "February",
  //     "March",
  //     "April",
  //     "May",
  //     "June",
  //     "July",
  //     "August",
  //     "September",
  //     "October",
  //     "November",
  //     "December",
  //   ];

  const handleChange = (evt) => {
    const value =
      evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
    setIsChanged(true);
  };

  const handleSlugChange = (evt) => {
    const slug = commonFunction.slugify(evt.target.value);
    setState({
      ...state,
      title: evt.target.value,
      slug: slug,
    });
    setIsChanged(true);
  };

  // Tags
  const handleTagsDelete = (i) => {
    var tagslist = tags.filter((tag, index) => index !== i);
    setTags(tagslist);
    setState({ ...state, tags: commonFunction.connectToList(tagslist) });
    setIsChanged(true);
  };

  const handleTagsAddition = (tag) => {
    var tagslist = tags;
    setTags([...tags, tag]);
    tagslist.push(tag);
    setState({ ...state, tags: commonFunction.connectToList(tagslist) });
    setIsChanged(true);
  };

  const handleLocationDelete = (i) => {
    var tagslist = locationTags.filter((tag, index) => index !== i);
    setLocationTags(tagslist);
    setState({ ...state, location: commonFunction.connectStr(tagslist) });
    setIsChanged(true);
  };

  const handleLocationAddition = (tag) => {
    var tagslist = locationTags;
    setLocationTags([...locationTags, tag]);
    tagslist.push(tag);
    setState({ ...state, location: commonFunction.connectStr(tagslist) });
    setIsChanged(true);
  };

  const getUploadedFiles = (list) => {
    setFiles(list);
    setIsChanged(true);
  };

  const getDeleteSavedFile = (file) => {
    var files = deleteSavedFiles;
    files.push(file);
    setDeleteSavedFiles(files);
    setIsChanged(true);
  };

  const onGenderChange = (newValue, actionMeta) => {
    setSelectedGender(newValue);
    setState({ ...state, gender: newValue.value });
    console.groupEnd();
  };

  const onChangeStartDate = (date) => {
    setState({ ...state, dob: date });
  };

  const createClicked = () => {
    if (location.state) {
      if (location.state.jobid) {
        var data = state;
        data.active = true;
        API.createItem("candidates", data, token)
          .then((resp) => {
            setProgress(0);
            FileUploadService.upload(
              "candidate_files",
              files,
              token,
              "candidate",
              resp.id,
              (event) => {
                const percentage = Math.round(
                  (100 * event.loaded) / event.total
                );
                setProgress(percentage);
                if (percentage == 100) {
                  setFiles([]);
                }
              }
            );

            if (
              resp.mobile != "" &&
              resp.mobile != "undefined" &&
              resp.email != "" &&
              resp.email != "undefined"
            ) {
              var applicant = {
                job: location.state.jobid,
                candidate: resp.id,
                status: "1",
                active: true,
              };
              API.createItem("applicants", applicant, token)
                .then((resp1) => {
                  var content =
                    "Candidate ID: " +
                    resp1.candidate.toString() +
                    " applied Job ID: " +
                    resp1.job.toString();
                  API.createItem(
                    "applicant_histories",
                    { applicant: resp1.id, content: content },
                    token
                  )
                    .then((resp2) => {
                      console.log(resp2);
                    })
                    .catch((error) => {
                      console.log(error);
                      toast.error(error);
                    });
                })
                .catch((error) => console.log(error));
            } else {
              console.log("Can not apply because mobile is empty.");
            }

            history.push("/candidates/");
          })
          .catch((error) => console.log(error));
      }
    } else {
      API.createItem("candidates", state, token)
        .then((resp) => {
          setProgress(0);
          FileUploadService.upload(
            "candidate_files",
            files,
            token,
            "candidate",
            resp.id,
            (event) => {
              const percentage = Math.round((100 * event.loaded) / event.total);
              setProgress(percentage);
              if (percentage == 100) {
                setFiles([]);
              }
            }
          );
          history.push("/candidates/");
        })
        .catch((error) => console.log(error));
    }
  };

  const updateClicked = () => {
    if (location.state && isChanged) {
      API.updateItem("candidates", location.state.id, state, token)
        .then((resp) => {
          if (files.length != 0) {
            FileUploadService.upload(
              "candidate_files",
              files,
              token,
              "candidate",
              location.state.id,
              (event) => {
                const percentage = Math.round(
                  (100 * event.loaded) / event.total
                );
                setProgress(percentage);
                if (percentage == 100) {
                  setFiles([]);
                }
              }
            );
          }
          if (deleteSavedFiles.length != 0) {
            deleteSavedFiles.map((file) => {
              API.deleteItem("candidate_files", file.id, token)
                .then((resp2) => {
                  console.log("Delete file: Done", resp2);
                })
                .catch((error) => console.log(error));
            });
            setDeleteSavedFiles([]);
          }
          history.push("/candidates/");
        })
        .catch((error) => console.log(error));
    }
  };

  const onCancel = () => {
    if (location.state) {
      if (location.state.jobid) {
        history.push({
          pathname: "/jobs/detail/",
          state: { id: location.state.jobid },
        });
      } else {
        history.push("/candidates/");
      }
    } else {
      history.push("/candidates/");
    }
  };

  useEffect(() => {
    setIsUpdating(false);

    if (location.state) {
      if (location.state.id) {
        setIsUpdating(true);
        setIsChanged(false);
        API.getItem("candidates", location.state.id, token)
          .then((resp) => {
            setState({
              ...state,
              name: resp.name,
              location: resp.location,
              gender: resp.gender,
              dob: resp.dob,
              current_position: resp.current_position,
              mobile: resp.mobile,
              email: resp.email,
              address: resp.address,
              social_link: resp.social_link,
              tags: resp.tags,
              active: resp.active,
              notes: resp.notes,
            });
            if (resp.location) {
              setLocationTags(commonFunction.splitStrToTags(resp.location));
            }
            if (resp.tags && resp.tags.length != 0) {
              setTags(commonFunction.splitListToTags(resp.tags));
            }
            setSelectedGender({
              value: resp.gender,
              label: getGenderLabel(resp.gender),
            });

            var link = "candidatefiles/" + resp.id.toString() + "/";
            API.getItems(link, token).then((resp2) => {
              setSavedFiles(resp2.files);
            });
          })
          .catch((error) => console.log(error));
      }
    }
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Create Candidate</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Create Candidate</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          <div className="card card-default">
            <div className="card-header">
              <h3 className="card-title">
                {location.state && location.state.id
                  ? "Update Candidate"
                  : "New Candidate"}
              </h3>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Name</label>
                    <input
                      id="name"
                      name="name"
                      className="form-control"
                      type="text"
                      placeholder="Enter name"
                      value={state.name}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Date of birth</label>
                    <input
                      id="dob"
                      name="dob"
                      className="form-control"
                      type="date"
                      placeholder="Enter date of birth"
                      value={state.dob}
                      onChange={handleChange}
                    />
                    {/* <CustomDatePicker
                      startDate={state.dob}
                      onChangeStartDate={onChangeStartDate}
                    /> */}
                    {/* <DatePicker selected={state.dob} onChange={onChangeStartDate} /> */}
                  </div>
                  <div className="form-group">
                    <label>Mobile</label>
                    <input
                      id="mobile"
                      name="mobile"
                      className="form-control"
                      type="number"
                      placeholder="Enter mobile"
                      value={state.mobile}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Social link</label>
                    <input
                      id="social_link"
                      name="social_link"
                      className="form-control"
                      type="text"
                      placeholder="Enter social link"
                      value={state.social_link}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Address</label>
                    <input
                      id="address"
                      name="address"
                      className="form-control"
                      type="text"
                      placeholder="Enter address"
                      value={state.address}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Gender</label>
                    <Select
                      className="basic-single selectbox-container"
                      classNamePrefix="select"
                      value={selectedGender}
                      name="gender"
                      options={genderList}
                      onChange={onGenderChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Current position</label>
                    <input
                      id="current_position"
                      name="current_position"
                      className="form-control"
                      type="text"
                      placeholder="Enter current position"
                      value={state.current_position}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      id="email"
                      name="email"
                      className="form-control"
                      type="text"
                      placeholder="Enter email"
                      value={state.email}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Location</label>
                    <ReactTags
                      id="location"
                      tags={locationTags}
                      delimiters={delimiters}
                      handleDelete={handleLocationDelete}
                      handleAddition={handleLocationAddition}
                    />
                  </div>
                  <div className="form-group clearfix">
                    <label>Is active?</label>
                    <div className="icheck-primary d-inline ustorekit-common-margin-10-side">
                      <input
                        type="checkbox"
                        checked={state.active}
                        id="active"
                        name="active"
                        onChange={handleChange}
                      />
                      <label htmlFor="active"></label>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Tag</label>
                    <ReactTags
                      id="tags"
                      tags={tags}
                      delimiters={delimiters}
                      handleDelete={handleTagsDelete}
                      handleAddition={handleTagsAddition}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Notes</label>
                    <MyQuill
                      handleChange={(data) => {
                        setState({ ...state, notes: data });
                        setIsChanged(true);
                      }}
                      data={state.notes}
                      token={token}
                      id="notes"
                      name="notes"
                    ></MyQuill>
                    {/* <textarea
                      className="form-control"
                      id="notes"
                      name="notes"
                      cols="100"
                      rows="6"
                      defaultValue={state.notes}
                      placeholder="Enter notes"
                      onChange={handleChange}
                    ></textarea> */}
                    {/* <CKEditor
                      id="notes"
                      name="notes"
                      editor={ClassicEditor}
                      data={state.notes}
                      onReady={(editor) => {
                        // You can store the "editor" and use when it is needed.
                        console.log("Editor is ready to use!", editor);
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setState({
                          ...state,
                          notes: data,
                        });
                      }}
                    /> */}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>CV</label>
                    <FilesUpload
                      getUploadedFiles={getUploadedFiles}
                      getDeleteSavedFile={getDeleteSavedFile}
                      savedFiles={savedFiles}
                      isUpdating={isUpdating}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer">
              {location.state && location.state.id ? (
                <button
                  className="btn btn-info"
                  onClick={updateClicked}
                  disabled={isDisabled}
                >
                  Update
                </button>
              ) : (
                <button
                  className="btn btn-info"
                  onClick={createClicked}
                  disabled={isDisabled}
                >
                  Create
                </button>
              )}
              <button
                className="btn btn-default float-right"
                onClick={() => {
                  if (
                    window.confirm("Are you sure you wish to delete this item?")
                  )
                    onCancel();
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </section>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}

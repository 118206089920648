import React, { useEffect, useState } from "react";

export default function Files(props) {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    setFiles(props.savedFileNames);
  }, [props.savedFileNames]);

  return (
    <React.Fragment>
      <div className="card card-primary">
        <div className="card-header">
          <h3 className="card-title">Files</h3>
        </div>
        <div className="card-body">
          <ul className="list-unstyled">
            {files &&
              files.map((file) => (
                <li key={file.id}>
                  <a
                    href="javascript:void(0)"
                    onClick={() => props.handleFileClick(file.preview)}
                  >
                    <i className="far fa-hand-point-right"></i>
                    {"   "}
                    {file.name}
                  </a>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
}

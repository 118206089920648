import React, { useState, useEffect, useContext } from "react";
import { API } from "../common/api-service";
import AuthContext from "../common/AuthContext";
import { Link, useHistory, useLocation } from "react-router-dom";
import { commonFunction } from "../common/common-functions";
import ReactHtmlParser from "react-html-parser";
import FindJobsBox from "../applicants/FindJobsBox";
import SlideDrawer from "../SlideDrawer/SlideDrawer";
import Backdrop from "../SlideDrawer/Backdrop";

// For react-dropzone
const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "relative",
  width: "auto",
  height: "100%",
};

const thumbName = {
  width: 100,
};

export default function CandidateForm(props) {
  const history = useHistory();
  const location = useLocation();
  const initialState = {
    id: "",
    name: "",
    location: null,
    gender: "",
    dob: "",
    current_position: "",
    mobile: "",
    email: "",
    address: "",
    social_link: "",
    tags: null,
    active: false,
    notes: "",
  };
  const [state, setState] = useState(initialState);

  const { authState } = useContext(AuthContext);
  const token = authState.token.slice(1, -1);

  const [savedFiles, setSavedFiles] = useState([]);
  const [locationTags, setLocationTags] = useState([]);
  const [appliedJobs, setAppliedJobs] = useState([]);

  const [hasFindJobs, setHasFindJobs] = useState(false);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [document, setDocument] = useState("");

  const drawerToggleClickHandler = () => {
    setDrawerOpen(!drawerOpen);
  };

  const backdropClickHandler = () => {
    setDrawerOpen(false);
  };

  let backdrop;
  if (drawerOpen) {
    backdrop = <Backdrop close={() => backdropClickHandler()} />;
  }

  const handleFileClick = (preview) => {
    setDocument(preview);
    drawerToggleClickHandler();
  };

  const savedThumbs =
    savedFiles &&
    savedFiles.map((file, i) => {
      return (
        <div key={file.name}>
          <div style={thumb}>
            <div style={thumbInner}>
              <a
                href="javascript:void(0)"
                onClick={() => handleFileClick(file.preview)}
              >
                <img src={file.preview} style={img} />
              </a>
            </div>
          </div>
          <div style={thumbName}>
            <a
              href="javascript:void(0)"
              onClick={() => handleFileClick(file.preview)}
            >
              {file.name}
            </a>
          </div>
          <div style={thumbName}>
            <a href={file.preview} rel="noopener noreferrer" target="_blank">
              Download
            </a>
          </div>
        </div>
      );
    });

  const displayLocationTags =
    locationTags &&
    locationTags.map((tag, i) => {
      return (
        <button
          className="btn btn-info btn-xs ustorekit-common-margin-10-right"
          key={tag.text}
        >
          {tag.text}
        </button>
      );
    });

  const deleteItem = () => {
    if (location.state) {
      if (location.state.id) {
        if (window.confirm("Are you sure you wish to delete this item?"))
          API.updateItem(
            "candidate_active",
            location.state.id,
            { active: false },
            token
          )
            .then((resp) => {
              setState({ ...state, active: resp.active });
            })
            .catch((error) => console.log(error));
      }
    }
  };

  const onUpdate = () => {
    if (location.state) {
      if (location.state.id) {
        history.push({
          pathname: "/candidates/create/",
          state: { id: location.state.id },
        });
      }
    }
  };

  const onBack = () => {
    if (location.state) {
      if (location.state.jobid) {
        history.push({
          pathname: "/candidates/detail/",
          state: { id: location.state.jobid },
        });
      } else {
        history.push("/candidates/");
      }
    } else {
      history.push("/candidates/");
    }
  };

  const getAppliedJobsByCandidate = (candidate_id) => {
    var link =
      "jobs/get_applied_jobs_by_candidate/" + candidate_id.toString() + "/";
    API.getItems(link, token).then((resp3) => {
      var jobsArr = resp3.jobs;
      var result = [];
      for (var m = 0; m < jobsArr.length; m++) {
        result.push({
          id: jobsArr[m].id,
          title: jobsArr[m].title,
        });
      }
      setAppliedJobs(result);
    });
  };

  // const onFindJobs = () => {
  //   if (location.state) {
  //     if (location.state.id || state.id) {
  //       var candidateid = location.state.id || state.id;
  //       history.push({
  //         pathname: "/applicants/find_jobs/",
  //         state: { id: candidateid },
  //       });
  //     }
  //   }
  // };

  useEffect(() => {
    if (location.state) {
      if (location.state.hasFindJobs) {
        setHasFindJobs(true);
      }
      if (location.state.id) {
        API.getItem("candidates", location.state.id, token)
          .then((resp) => {
            setState({
              ...state,
              id: resp.id,
              name: resp.name,
              location: resp.location,
              gender: resp.gender,
              dob: resp.dob,
              current_position: resp.current_position,
              mobile: resp.mobile,
              email: resp.email,
              address: resp.address,
              social_link: resp.social_link,
              tags: resp.tags,
              active: resp.active,
              notes: resp.notes,
            });
            setLocationTags(commonFunction.splitStrToTags(resp.location));

            var link = "candidatefiles/" + resp.id.toString() + "/";
            API.getItems(link, token).then((resp2) => {
              var old_files = resp2.files;
              var new_files = [];
              for (var i = 0, l = old_files.length; i < l; i++) {
                new_files.push({
                  id: old_files[i].id,
                  candidate: old_files[i].candidate,
                  name: old_files[i].name,
                  size: old_files[i].size,
                  lastModifiedDate: old_files[i].lastModifiedDate,
                  preview: old_files[i].file,
                });
              }
              setSavedFiles(new_files);
            });

            // Get applied cadidates
            getAppliedJobsByCandidate(resp.id);
          })
          .catch((error) => console.log(error));
      }
    }
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Candidate Detail</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Candidate Detail</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          <div className="card">
            <div className="card-header">
              <div className="row">
                <div className="col-md-8">
                  <h3 className="card-title">
                    {state.id} - {state.name}
                  </h3>
                </div>
                <div className="col-md-4 text-center">
                  <button
                    className="btn btn-sm btn-warning ustorekit-common-margin-10-right"
                    onClick={() => {
                      setHasFindJobs(true);
                    }}
                  >
                    Find jobs
                  </button>
                  <button
                    className="btn btn-sm btn-primary ustorekit-common-margin-10-right"
                    onClick={onUpdate}
                  >
                    Update
                  </button>
                  <button
                    className="btn btn-sm btn-danger ustorekit-common-margin-10-right"
                    onClick={deleteItem}
                  >
                    Delete
                  </button>
                  <button
                    className="btn btn-sm btn-default ustorekit-common-margin-10-right"
                    onClick={onBack}
                  >
                    Back
                  </button>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-12 col-md-12 col-lg-8 order-2 order-md-1">
                  <div className="row">
                    <div className="col-12 col-sm-4">
                      <div className="info-box bg-light">
                        <div className="info-box-content">
                          <span className="info-box-text text-center text-muted">
                            Location
                          </span>
                          <span className="info-box-number text-center text-muted mb-0">
                            {state.location}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-4">
                      <div className="info-box bg-light">
                        <div className="info-box-content">
                          <span className="info-box-text text-center text-muted">
                            Mobile
                          </span>
                          <span className="info-box-number text-center text-muted mb-0">
                            {state.mobile}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-4">
                      <div className="info-box bg-light">
                        <div className="info-box-content">
                          <span className="info-box-text text-center text-muted">
                            Email
                          </span>
                          <span className="info-box-number text-center text-muted mb-0">
                            {state.email}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <h5>Informations</h5>
                      <div className="text-muted">
                        <p className="text-sm">
                          <b className="d-block">Location</b>
                          {displayLocationTags}
                        </p>
                        <p className="text-sm">
                          <b className="d-block">Gender</b>
                          {state.gender == "M" ? "Male" : "Female"}
                        </p>
                        <p className="text-sm">
                          <b className="d-block">Date of birth</b>
                          {state.dob}
                        </p>
                        <p className="text-sm">
                          <b className="d-block">Current position</b>
                          {state.current_position}
                        </p>
                        <p className="text-sm">
                          <b className="d-block">Mobile</b>
                          {state.mobile}
                        </p>
                        <p className="text-sm">
                          <b className="d-block">Email</b>
                          {state.email}
                        </p>
                        <p className="text-sm">
                          <b className="d-block">Address</b>
                          {state.address}
                        </p>
                        <p className="text-sm">
                          <b className="d-block">Social link</b>
                          {state.social_link}
                        </p>
                        <p className="text-sm">
                          <b className="d-block">Is active?</b>
                          {state.active ? "Yes" : "No"}
                        </p>
                        <p className="text-sm">
                          <b className="d-block">Note</b>
                          {ReactHtmlParser(state.notes)}
                        </p>
                        <div className="text-sm">
                          <b className="d-block">CV</b>
                          <aside style={thumbsContainer}>{savedThumbs}</aside>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-12 col-lg-4 order-1 order-md-2">
                  <h3 className="text-primary">
                    <i className="fas fa-paint-brush"></i> Applied jobs
                  </h3>
                  <ul className="list-unstyled">
                    {appliedJobs &&
                      appliedJobs.map((appliedjob) => (
                        <li key={appliedjob.id}>
                          <Link
                            to={{
                              pathname: `/jobs/detail/`,
                              state: { id: appliedjob.id },
                            }}
                            className="btn-link text-secondary"
                          >
                            <i className="far fa-hand-point-right"></i>
                            {"   "}
                            {appliedjob.id}
                            {" - "}
                            {appliedjob.title}
                          </Link>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {hasFindJobs ? (
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Jobs Box</h3>
                <div className="card-tools">
                  <button
                    type="button"
                    className="btn btn-tool"
                    data-card-widget="collapse"
                    data-toggle="tooltip"
                    title="Collapse"
                  >
                    <i className="fas fa-minus"></i>
                  </button>
                  <button
                    type="button"
                    className="btn btn-tool"
                    data-card-widget="remove"
                    data-toggle="tooltip"
                    title="Remove"
                    onClick={() => {
                      setHasFindJobs(false);
                    }}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
              <div className="card-body">
                <div className="relativeWrapper">
                  <div className="absoluteWrapper"></div>
                  <FindJobsBox
                    candidate_id={state.id}
                    refreshAppliedJobs={() =>
                      getAppliedJobsByCandidate(state.id)
                    }
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {/* {openCard ? (
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Documents</h3>
                <div className="card-tools">
                  <button
                    type="button"
                    className="btn btn-tool"
                    data-card-widget="collapse"
                    data-toggle="tooltip"
                    title="Collapse"
                  >
                    <i className="fas fa-minus"></i>
                  </button>
                  <button
                    type="button"
                    className="btn btn-tool"
                    data-card-widget="remove"
                    data-toggle="tooltip"
                    title="Remove"
                    onClick={() => {
                      setOpenCard(!openCard);
                    }}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
              <div className="card-body">
                <Documents url={document} />
              </div>
            </div>
          ) : (
            ""
          )} */}
        </section>
      </div>
      <SlideDrawer show={drawerOpen} url={document} />
      {backdrop}
    </React.Fragment>
  );
}

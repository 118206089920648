import React, { useState, useEffect, useContext } from "react";
import { API } from "../common/api-service";
import AuthContext from "../common/AuthContext";
import { commonFunction } from "../common/common-functions";
import "../common/custom_css.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddIcon from "@mui/icons-material/Add";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";

export default function FindJobsBox({ candidate_id, refreshAppliedJobs }) {
  // const candidate_id = props.candidate_id;
  const { authState } = useContext(AuthContext);
  const token = authState.token.slice(1, -1);

  const renderTags = (tag) => {
    return (
      <button className="btn btn-secondary btn-xs tags-margin" key={tag.text}>
        {tag.text}
      </button>
    );
  };

  const getJobsByCandidate = () => {
    var link = "jobs/find_jobs_by_candidate/" + candidate_id.toString() + "/";
    API.getItems(link, token).then((resp) => {
      const rows = createRowsList(resp.jobs, job_columns);
      setJobs({
        ...jobs,
        columns: job_columns,
        rows: rows,
      });
    });
  };

  const apply_job = (row_id) => {
    // Check if candidate applicated
    var link =
      "candidates/applied_candidate_for_job/" +
      row_id +
      "/" +
      candidate_id +
      "/";
    API.getItems(link, token).then((resp1) => {
      if (resp1.applicants.length <= 0) {
        // TO DO: check if candidate active or not
        var data = {
          job: row_id,
          candidate: candidate_id,
          status: "1",
          active: true,
        };
        API.createItem("applicants", data, token)
          .then((resp) => {
            var content =
              "Candidate ID: " +
              resp.candidate.toString() +
              " applied Job ID: " +
              resp.job.toString();
            API.createItem(
              "applicant_histories",
              { applicant: resp.id, content: content },
              token
            )
              .then((resp2) => {
                console.log(resp2);
              })
              .catch((error) => {
                console.log(error);
                toast.error(error);
              });
            toast.success("Application successful");
            getJobsByCandidate();
            // props.refreshAppliedJobs();
            refreshAppliedJobs();
          })
          .catch((error) => {
            console.log(error);
            toast.error(error);
          });
      }
    });
  };

  const job_columns = React.useMemo(
    () => [
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        width: 100,
        cellClassName: "actions",
        getActions: (params) => {
          return [
            <GridActionsCellItem
              icon={<AddIcon />}
              label="Add"
              onClick={() => apply_job(params.id)}
              color="inherit"
            />,
          ];
        },
      },
      { field: "id", headerName: "ID", width: 50 },
      { field: "title", headerName: "Title", width: 250 },
      {
        field: "location",
        headerName: "Location",
        width: 200,
        renderCell: (cellValues) => {
          var tags = cellValues.row.location;
          if (tags && tags.length != 0) {
            return (
              <>
                {tags &&
                  tags.map((tag, i) => {
                    return renderTags(tag);
                  })}
              </>
            );
          } else {
            return <></>;
          }
        },
      },
      { field: "salary", headerName: "Salary", width: 100 },
      { field: "numofvacancies", headerName: "Quantity", width: 100 },
      {
        field: "level",
        headerName: "Level",
        width: 200,
        renderCell: (cellValues) => {
          var tags = cellValues.row.level;
          if (tags && tags.length != 0) {
            return (
              <>
                {tags &&
                  tags.map((tag, i) => {
                    return renderTags(tag);
                  })}
              </>
            );
          } else {
            return <></>;
          }
        },
      },
      { field: "reward", headerName: "Reward", width: 100 },
      { field: "company", headerName: "Company", width: 150 },
      { field: "active", headerName: "Is active?", width: 100 },
    ],
    [apply_job]
  );

  const initialState = {
    columns: [],
    rows: [],
  };

  const [jobs, setJobs] = useState(initialState);

  function createRowsList(rows, cols) {
    let validrows = [];
    rows &&
      rows.forEach((row) => {
        let validrow = {};
        cols &&
          cols.forEach((col) => {
            switch (col.field) {
              case "location":
              case "level":
                if (row[col.field]) {
                  var tags = commonFunction.splitStrToTags(row[col.field]);
                  validrow[col.field] = tags;
                } else {
                  validrow[col.field] = [];
                }
                break;
              case "company":
                var link =
                  "get_company_name_by_company_id/" + row[col.field].toString();
                API.getItems(link, token).then((resp) => {
                  validrow[col.field] = resp;
                });
                break;
              case "active":
                if (row[col.field]) {
                  validrow[col.field] = "✔";
                } else {
                  validrow[col.field] = "×";
                }
                break;
              default:
                validrow[col.field] = row[col.field];
                validrow["id"] = row["id"];
                break;
            }
          });
        validrows.push(validrow);
      });
    return validrows;
  }

  useEffect(() => {
    if (candidate_id) {
      getJobsByCandidate();
    }

    // window.scrollTo(0, 0);
  }, [candidate_id]);

  return (
    <React.Fragment>
      <DataGrid rows={jobs.rows} columns={job_columns} />
      <ToastContainer />
    </React.Fragment>
  );
}

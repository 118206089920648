import React, { useMemo, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function MyQuill(props) {
  const quillRef = useRef();

  const imageHandler = (e) => {
    const editor = quillRef.current.getEditor();
    console.log(editor);
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      return new Promise((resolve, reject) => {
        const file = input.files[0];
        if (/^image\//.test(file.type)) {
          const formData = new FormData();
          formData.append("name", file.name);
          formData.append("size", file.size);
          formData.append("type", file.type);
          formData.append("lastModifiedDate", file.lastModifiedDate);
          formData.append("file", file);

          // fetch(`${process.env.REACT_APP_API_URL}/${path}/`, {
          fetch(`${process.env.REACT_APP_API_URL}/ckefiles/`, {
            headers: {
              Authorization: `Token ${props.token}`,
            },
            method: "post",
            body: formData,
            // mode: "no-cors"
          })
            .then((res) => res.json())
            .then((res) => {
              resolve({
                default: res.file,
              });
            })
            .catch((err) => {
              reject(err);
            });
        } else {
          console.log("You could only upload images.");
        }
      });
    };
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ font: [] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["bold", "italic", "underline", "strike"],
          [{ color: [] }, { background: [] }],
          [{ script: "sub" }, { script: "super" }],
          ["blockquote", "code-block"],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
          ["link", "image", "video"],
          ["clean"],
        ],
        handlers: {
          image: imageHandler,
        },
      },
      // table: true,
    }),
    []
  );

  // modules = {
  //     toolbar: {
  //       container: [
  //         ["bold", "italic", "underline", "strike", "blockquote"],
  //         [{ size: ["small", false, "large", "huge"] }, { color: [] }],
  //         [
  //           { list: "ordered" },
  //           { list: "bullet" },
  //           { indent: "-1" },
  //           { indent: "+1" },
  //           { align: [] }
  //         ],
  //         ["link", "image", "video"],
  //         ["clean"]
  //       ],
  //       handlers: { image: this.imageHandler }
  //     },
  //     clipboard: { matchVisual: false }
  //   };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "size",
    "color",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "align",
  ];
  return (
    <ReactQuill
      id={props.id}
      name={props.name}
      theme="snow"
      value={props.data}
      onChange={(html) => {
        props.handleChange(html);
      }}
      modules={modules}
      formats={formats}
      placeholder="Enter description..."
    />
  );
}

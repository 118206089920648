import React from "react";
import "./SlideDrawer.css";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

export default function SlideDrawer(props) {
  const drawerClasses = props.show ? "side-drawer open" : "side-drawer";

  const docs = [
    {
      uri: props.url,
    }, // Remote file
    // { uri: require("./hn.docx"), fileType: 'docx' }, // Local File
  ];

  return (
    <React.Fragment>
      <div className={drawerClasses}>
        <a
          href={props.url}
          rel="noopener noreferrer"
          target="_blank"
          style={{ marginTop: 30, marginBottom: 30 }}
        >
          Download
        </a>
        <DocViewer
          documents={docs}
          pluginRenderers={DocViewerRenderers}
          style={{ height: 1000 }}
        />
      </div>
    </React.Fragment>
  );
}

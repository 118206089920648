import React from "react";
import axios from "axios";

const upload = (path, validFiles, token, item, itemID, onUploadProgress) => {
  var list = [];
  for (let i = 0; i < validFiles.length; i++) {
    const formData = new FormData();
    formData.append(item, itemID);
    formData.append("name", validFiles[i].name);
    formData.append("size", validFiles[i].size);
    formData.append("type", validFiles[i].type);
    formData.append("lastModifiedDate", validFiles[i].lastModifiedDate);
    formData.append("file", validFiles[i]);

    axios
      .post(`${process.env.REACT_APP_API_URL}/${path}/`, formData, {
        headers: {
          Authorization: `Token ${token}`,
        },
        onUploadProgress,
      })
      .then((response) => list.push(response.data.id))
      .catch((e) => {
        console.log(`😱 Axios request failed: ${e.response.data}`);
      });
  }
  return list;
};

export default {
  upload,
};

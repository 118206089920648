import React, { useState, useEffect, useContext } from "react";
import "../common/custom_css.css";
import AuthContext from "../common/AuthContext";
import { API } from "../common/api-service";
import { Link, useHistory } from "react-router-dom";
import { commonFunction } from "../common/common-functions";
import ArticleIcon from "@mui/icons-material/Article";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";

export default function Jobs() {
  const history = useHistory();
  const { authState } = useContext(AuthContext);
  const token = authState.token.slice(1, -1);

  const deleteRow = (id) => {
    if (window.confirm("Are you sure you wish to delete this item?"))
      API.updateItem("job_active", id, { active: false }, token)
        // API.deleteItem("jobs", id, token)
        .then((resp) => {
          API.getItems("jobs/", token)
            .then((resp2) => {
              const rows = createRowsList(resp2, columns);
              setState({
                ...state,
                columns: columns,
                rows: rows,
              });
            })
            .catch((error) => console.log(error));
          history.push("/jobs/");
        })
        .catch((error) => console.log(error));
  };

  const activeRow = (id) => {
    if (window.confirm("Are you sure you wish to add this item?"))
      API.updateItem("job_active", id, { active: true }, token)
        // API.deleteItem("jobs", id, token)
        .then((resp) => {
          API.getItems("jobs/", token)
            .then((resp2) => {
              const rows = createRowsList(resp2, columns);
              setState({
                ...state,
                columns: columns,
                rows: rows,
              });
            })
            .catch((error) => console.log(error));
          history.push("/jobs/");
        })
        .catch((error) => console.log(error));
  };

  const renderTags = (tag) => {
    return (
      <>
        <button className="btn btn-secondary btn-xs tags-margin" key={tag.text}>
          {tag.text}
        </button>
      </>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        width: 150,
        cellClassName: "actions",
        getActions: (params) => {
          if (params.row.active === "✔") {
            return [
              <GridActionsCellItem
                icon={<ArticleIcon />}
                label="Detail"
                className="textPrimary"
                component={Link}
                to={{
                  pathname: `/jobs/detail/`,
                  state: { id: params.id },
                }}
                color="inherit"
              />,
              <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                className="textPrimary"
                component={Link}
                to={{
                  pathname: `/jobs/create/`,
                  state: { id: params.id },
                }}
                color="inherit"
              />,
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={() => deleteRow(params.id)}
                color="inherit"
              />,
            ];
          } else {
            return [
              <GridActionsCellItem
                icon={<ArticleIcon />}
                label="Detail"
                className="textPrimary"
                component={Link}
                to={{
                  pathname: `/jobs/detail/`,
                  state: { id: params.id },
                }}
                color="inherit"
              />,
              <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                className="textPrimary"
                component={Link}
                to={{
                  pathname: `/jobs/create/`,
                  state: { id: params.id },
                }}
                color="inherit"
              />,
              <GridActionsCellItem
                icon={<AddIcon />}
                label="Add"
                onClick={() => activeRow(params.id)}
                color="inherit"
              />,
            ];
          }
        },
      },
      { field: "id", headerName: "ID", width: 50 },
      { field: "title", headerName: "Title", width: 250 },
      {
        field: "location",
        headerName: "Location",
        width: 200,
        renderCell: (cellValues) => {
          var tags = cellValues.row.location;
          if (tags && tags.length != 0) {
            return (
              <>
                {tags &&
                  tags.map((tag, i) => {
                    return renderTags(tag);
                  })}
              </>
            );
          } else {
            return <></>;
          }
        },
      },
      { field: "salary", headerName: "Salary", width: 100 },
      { field: "numofvacancies", headerName: "Quantity", width: 100 },
      {
        field: "level",
        headerName: "Level",
        width: 200,
        renderCell: (cellValues) => {
          var tags = cellValues.row.level;
          if (tags && tags.length != 0) {
            return (
              <>
                {tags &&
                  tags.map((tag, i) => {
                    return renderTags(tag);
                  })}
              </>
            );
          } else {
            return <></>;
          }
        },
      },
      { field: "reward", headerName: "Reward", width: 100 },
      { field: "company", headerName: "Company", width: 150 },
      { field: "active", headerName: "Is active?", width: 100 },
    ],
    [deleteRow, activeRow]
  );

  const initialState = {
    columns: [],
    rows: [],
  };

  const [state, setState] = useState(initialState);

  function createRowsList(rows, cols) {
    let validrows = [];
    rows &&
      rows.forEach((row) => {
        let validrow = {};
        cols &&
          cols.forEach((col) => {
            switch (col.field) {
              case "location":
              case "level":
                if (row[col.field]) {
                  var tags = commonFunction.splitStrToTags(row[col.field]);
                  validrow[col.field] = tags;
                } else {
                  validrow[col.field] = [];
                }
                break;
              case "company":
                var link =
                  "get_company_name_by_company_id/" + row[col.field].toString();
                API.getItems(link, token).then((resp) => {
                  validrow[col.field] = resp;
                });
                break;
              case "active":
                if (row[col.field]) {
                  validrow[col.field] = "✔";
                } else {
                  validrow[col.field] = "×";
                }
                break;
              default:
                validrow[col.field] = row[col.field];
                validrow["id"] = row["id"];
                break;
            }
          });
        validrows.push(validrow);
      });
    return validrows;
  }

  const newRow = () => {
    history.push({
      pathname: "/jobs/create/",
    });
  };

  useEffect(() => {
    API.getItems("jobs_list", token)
      .then((resp) => {
        const rows = createRowsList(resp, columns);
        setState({
          ...state,
          columns: columns,
          rows: rows,
        });
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <React.Fragment>
      <div className="toolbar row ustorekit-common-under-margin-10">
        <div className="col-md-2">
          <button className="cell-button btn btn-success" onClick={newRow}>
            Add a Job
          </button>
        </div>
      </div>
      <div className="relativeWrapper">
        <div className="absoluteWrapper"></div>
        <DataGrid rows={state.rows} columns={columns} />
      </div>
      <div></div>
    </React.Fragment>
  );
}

import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../common/AuthContext";
import { API } from "../common/api-service";
import "../common/custom_css.css";
import { Link, useHistory } from "react-router-dom";
import { commonFunction } from "../common/common-functions";
import ArticleIcon from "@mui/icons-material/Article";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";

export default function Companies() {
  const history = useHistory();
  const { authState } = useContext(AuthContext);
  const token = authState.token.slice(1, -1);

  const deleteRow = (id) => {
    if (window.confirm("Are you sure you wish to delete this item?"))
      API.updateItem("companies", id, { active: false }, token)
        // API.deleteItem("companies", id, token)
        .then((resp) => {
          API.getItems("companies/", token)
            .then((resp2) => {
              const rows = createRowsList(resp2, columns);
              setState({
                ...state,
                columns: columns,
                rows: rows,
              });
            })
            .catch((error) => console.log(error));
          history.push("/jobs/");
        })
        .catch((error) => console.log(error));
  };

  const activeRow = (id) => {
    if (window.confirm("Are you sure you wish to active this item?"))
      API.updateItem("companies", id, { active: true }, token)
        // API.deleteItem("companies", id, token)
        .then((resp) => {
          API.getItems("companies/", token)
            .then((resp2) => {
              const rows = createRowsList(resp2, columns);
              setState({
                ...state,
                columns: columns,
                rows: rows,
              });
            })
            .catch((error) => console.log(error));
          history.push("/jobs/");
        })
        .catch((error) => console.log(error));
  };

  const renderTags = (tag) => {
    return (
      <button className="btn btn-secondary btn-xs tags-margin" key={tag.text}>
        {tag.text}
      </button>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        width: 150,
        cellClassName: "actions",
        getActions: (params) => {
          if ((params.row.active === "✔")) {
            return [
              <GridActionsCellItem
                icon={<ArticleIcon />}
                label="Detail"
                className="textPrimary"
                component={Link}
                to={{
                  pathname: `/companies/detail/`,
                  state: { id: params.id },
                }}
                color="inherit"
              />,
              <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                className="textPrimary"
                component={Link}
                to={{
                  pathname: `/companies/create/`,
                  state: { id: params.id },
                }}
                color="inherit"
              />,
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={() => deleteRow(params.id)}
                color="inherit"
              />,
            ];
          } else {
            return [
              <GridActionsCellItem
                icon={<ArticleIcon />}
                label="Detail"
                className="textPrimary"
                component={Link}
                to={{
                  pathname: `/companies/detail/`,
                  state: { id: params.id },
                }}
                color="inherit"
              />,
              <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                className="textPrimary"
                component={Link}
                to={{
                  pathname: `/companies/create/`,
                  state: { id: params.id },
                }}
                color="inherit"
              />,
              <GridActionsCellItem
                icon={<AddIcon />}
                label="Add"
                onClick={() => activeRow(params.id)}
                color="inherit"
              />,
            ];
          }
        },
      },
      { field: "id", headerName: "ID", width: 50 },
      { field: "name", headerName: "Name", width: 250 },
      {
        field: "location",
        headerName: "Location",
        width: 200,
        renderCell: (cellValues) => {
          var tags = cellValues.row.location;
          if (tags && tags.length != 0) {
            return (
              <>
                {tags &&
                  tags.map((tag, i) => {
                    return renderTags(tag);
                  })}
              </>
            );
          } else {
            return <></>;
          }
        },
      },
      { field: "website", headerName: "Website", width: 200 },
      { field: "mobile", headerName: "Mobile", width: 200 },
      { field: "active", headerName: "Is active?", width: 100 },
    ],
    [deleteRow, activeRow]
  );

  const initialState = {
    columns: [],
    rows: [],
  };

  const [state, setState] = useState(initialState);

  const newRow = () => {
    history.push({
      pathname: "/companies/create/",
    });
  };

  function createRowsList(rows, cols) {
    let validrows = [];
    rows &&
      rows.forEach((row) => {
        let validrow = {};
        cols &&
          cols.forEach((col) => {
            switch (col.field) {
              case "location":
                if (row[col.field]) {
                  var tags = commonFunction.splitStrToTags(row[col.field]);
                  validrow[col.field] = tags;
                } else {
                  validrow[col.field] = [];
                }
                break;
              case "active":
                if (row[col.field]) {
                  validrow[col.field] = "✔";
                } else {
                  validrow[col.field] = "×";
                }
                break;
              default:
                validrow[col.field] = row[col.field];
                validrow["id"] = row["id"];
            }
          });
        validrows.push(validrow);
      });
    return validrows;
  }

  useEffect(() => {
    API.getItems("companies", token)
      .then((resp) => {
        const rows = createRowsList(resp, columns);
        setState({
          ...state,
          columns: columns,
          rows: rows,
        });
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <React.Fragment>
      <div className="toolbar row ustorekit-common-under-margin-10">
        <div className="col-md-2">
          <button className="cell-button btn btn-success" onClick={newRow}>
            Add a Company
          </button>
        </div>
      </div>
      <div className="relativeWrapper">
        <div className="absoluteWrapper"></div>
        <DataGrid rows={state.rows} columns={columns} />
      </div>
      <div></div>
    </React.Fragment>
  );
}

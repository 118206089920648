import React, { useState, useEffect, useContext } from "react";
import { API } from "../common/api-service";
import useFetch from "../../hooks/useFetch";
import { commonFunction } from "../common/common-functions";
import { dataCommonFunction } from "../common/data-common-function";
import FilesUpload from "../common/FilesUpload";
import FileUploadService from "../common/FileUploadService";
import AuthContext from "../common/AuthContext";
import { useHistory, useLocation } from "react-router-dom";
// import MyEditor from "../common/MyEditor";
import MyQuill from "../common/MyQuill";
import { WithContext as ReactTags } from "react-tag-input";
import CreatableSelect from "react-select/creatable";

// For react-select
const KeyCodes = {
  comma: 188,
  enter: [10, 13],
};
const delimiters = [...KeyCodes.enter, KeyCodes.comma];

export default function JobForm(props) {
  const history = useHistory();
  const location = useLocation();
  const initialState = {
    id: "",
    title: "",
    slug: "",
    location: null,
    salary: "",
    numofvacancies: 0,
    level: null,
    reward: 0,
    // description: "",
    // otherinfos: "",
    tags: "",
    company: "",
    active: false,
    // notes: "",
  };
  const [state, setState] = useState(initialState);
  const [description, setDescription] = useState("");
  const [otherinfos, setOtherinfos] = useState("");
  const [notes, setNotes] = useState("");
  const [tags, setTags] = useState([]);
  const [locationTags, setLocationTags] = useState([]);
  const [levelTags, setLevelTags] = useState([]);

  // Companies
  const companies_db = useFetch(
    `${process.env.REACT_APP_API_URL}/companies/`,
    "GET"
  );
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState([]);

  // Files
  const [files, setFiles] = useState([]);
  const [savedFiles, setSavedFiles] = useState([]);
  const [deleteSavedFiles, setDeleteSavedFiles] = useState([]);
  const [progress, setProgress] = useState(0);
  const [isUpdating, setIsUpdating] = useState(false);

  const { authState } = useContext(AuthContext);
  const token = authState.token.slice(1, -1);

  const [isLoading, setIsLoading] = useState(false);
  const customStyles = {
    control: (base, state) => ({
      ...base,
      height: 5,
    }),
    option: (base, state) => ({
      ...base,
      height: 35,
    }),
  };

  const [isChanged, setIsChanged] = useState(false);
  const isDisabled =
    state.title.length === 0 ||
    state.slug.length === 0 ||
    description.length === 0 ||
    !isChanged;

  const handleChange = (evt) => {
    const value =
      evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
    setIsChanged(true);
  };

  const handleSlugChange = (evt) => {
    const slug = commonFunction.slugify(evt.target.value);
    setState({
      ...state,
      title: evt.target.value,
      slug: slug,
    });
    setIsChanged(true);
  };

  // Tags
  const handleTagsDelete = (i) => {
    var tagslist = tags.filter((tag, index) => index !== i);
    setTags(tagslist);
    setState({ ...state, tags: commonFunction.connectToList(tagslist) });
    setIsChanged(true);
  };

  const handleTagsAddition = (tag) => {
    var tagslist = tags;
    setTags([...tags, tag]);
    tagslist.push(tag);
    setState({ ...state, tags: commonFunction.connectToList(tagslist) });
    setIsChanged(true);
  };

  // Location tags
  const handleLocationAddition = (tag) => {
    var tagslist = locationTags;
    setLocationTags([...locationTags, tag]);
    tagslist.push(tag);
    setState({ ...state, location: commonFunction.connectStr(tagslist) });
    setIsChanged(true);
  };

  const handleLocationDelete = (i) => {
    var tagslist = locationTags.filter((tag, index) => index !== i);
    setLocationTags(tagslist);
    setState({ ...state, location: commonFunction.connectStr(tagslist) });
    setIsChanged(true);
  };

  // Level tags
  const handleLevelAddition = (tag) => {
    var tagslist = levelTags;
    setLevelTags([...levelTags, tag]);
    tagslist.push(tag);
    setState({ ...state, level: commonFunction.connectStr(tagslist) });
    setIsChanged(true);
  };

  const handleLevelDelete = (i) => {
    var tagslist = levelTags.filter((tag, index) => index !== i);
    setLevelTags(tagslist);
    setState({ ...state, level: commonFunction.connectStr(tagslist) });
    setIsChanged(true);
  };

  const onCompanyChange = (newValue, actionMeta) => {
    setSelectedCompany(newValue);
    setState({
      ...state,
      company: newValue.value,
    });
    setIsChanged(true);
    console.groupEnd();
  };

  const getUploadedFiles = (list) => {
    setFiles(list);
    setIsChanged(true);
  };

  const getDeleteSavedFile = (file) => {
    var files = deleteSavedFiles;
    files.push(file);
    setDeleteSavedFiles(files);
    setIsChanged(true);
  };

  const handleCompanyCreate = (inputValue) => {
    setIsLoading(true);
    console.group("Option created");
    setTimeout(() => {
      const company = {
        name: inputValue,
        slug: commonFunction.slugify(inputValue),
      };
      API.createItem("companies", company, token)
        .then((resp) => {
          API.getItems("companies", token)
            .then((resp1) => {
              const company_options = dataCommonFunction.createSelectOption(
                companies_db.response
              );
              const newOption = {
                label: inputValue,
                value: commonFunction.slugify(inputValue),
              };
              setCompanies(company_options);
              setSelectedCompany(newOption);
              setIsLoading(false);
            })
            .catch((error) => console.log(error));
        })
        .catch((error) => console.log(error));

      console.groupEnd();
    }, 1000);
  };

  const createClicked = () => {
    var data = state;
    data["description"] = description;
    data["otherinfos"] = otherinfos;
    data["notes"] = notes;
    setState(data);
    API.createItem("jobs", state, token)
      .then((resp) => {
        setProgress(0);
        FileUploadService.upload(
          "job_files",
          files,
          token,
          "job",
          resp.id,
          (event) => {
            const percentage = Math.round((100 * event.loaded) / event.total);
            setProgress(percentage);
            if (percentage == 100) {
              setFiles([]);
            }
          }
        );
        history.push("/jobs/");
      })
      .catch((error) => console.log(error));
  };

  const updateClicked = () => {
    if (location.state && isChanged) {
      var data = state;
      data["description"] = description;
      data["otherinfos"] = otherinfos;
      data["notes"] = notes;
      setState(data);
      API.updateItem("jobs", location.state.id, state, token)
        .then((resp) => {
          if (files.length != 0) {
            FileUploadService.upload(
              "job_files",
              files,
              token,
              "job",
              location.state.id,
              (event) => {
                const percentage = Math.round(
                  (100 * event.loaded) / event.total
                );
                setProgress(percentage);
                if (percentage == 100) {
                  setFiles([]);
                }
              }
            );
          }
          if (deleteSavedFiles.length != 0) {
            deleteSavedFiles.map((file) => {
              API.deleteItem("job_files", file.id, token)
                .then((resp2) => {
                  console.log("Delete file: Done", resp2);
                })
                .catch((error) => console.log(error));
            });
            setDeleteSavedFiles([]);
          }
          history.push("/jobs/");
        })
        .catch((error) => console.log(error));
    }
  };

  const onCancel = () => {
    history.push("/jobs/");
  };

  useEffect(() => {
    // Company
    const company_options = dataCommonFunction.createSelectOption(
      companies_db.response
    );
    setCompanies(company_options);
    setIsUpdating(false);

    if (location.state) {
      if (location.state.id) {
        setIsUpdating(true);
        setIsChanged(false);
        API.getItem("jobs", location.state.id, token)
          .then((resp) => {
            setState({
              ...state,
              title: resp.title,
              slug: resp.slug,
              location: resp.location,
              salary: resp.salary,
              numofvacancies: resp.numofvacancies,
              level: resp.level,
              reward: resp.reward,
              // description: resp.description,
              // otherinfos: resp.otherinfos,
              tags: resp.tags,
              company: resp.company,
              active: resp.active,
              // notes: resp.notes,
            });
            setDescription(resp.description);
            setOtherinfos(resp.otherinfos);
            setNotes(resp.notes);
            
            if (resp.location && resp.location.length != 0) {
              setLocationTags(commonFunction.splitStrToTags(resp.location));
            }
            if (resp.level && resp.level.length != 0) {
              setLevelTags(commonFunction.splitStrToTags(resp.level));
            }
            if (resp.tags && resp.tags.length != 0) {
              setTags(commonFunction.splitListToTags(resp.tags));
            }

            API.getItem("companies", resp.company, token).then((resp1) => {
              setSelectedCompany({ value: resp1.id, label: resp1.name });
            });
            var link = "jobfiles/" + resp.id.toString() + "/";
            API.getItems(link, token).then((resp2) => {
              setSavedFiles(resp2.files);
            });
          })
          .catch((error) => console.log(error));
      }
    }
    window.scrollTo(0, 0);
  }, [companies_db.response]);

  return (
    <React.Fragment>
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Create Job</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Create Job</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          <div className="card card-default">
            <div className="card-header">
              <h3 className="card-title">
                {location.state && location.state.id ? "Update Job" : "New Job"}
              </h3>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Title</label>
                    <input
                      id="title"
                      name="title"
                      className="form-control"
                      type="text"
                      placeholder="Enter title"
                      value={state.title}
                      onChange={handleSlugChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Salary</label>
                    <input
                      id="salary"
                      name="salary"
                      className="form-control"
                      type="text"
                      placeholder="Enter salary"
                      value={state.salary}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Reward (VND)</label>
                    <input
                      id="reward"
                      name="reward"
                      className="form-control"
                      type="number"
                      placeholder="Enter reward"
                      value={state.reward}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Slug</label>
                    <input
                      id="slug"
                      name="slug"
                      className="form-control"
                      type="text"
                      placeholder="Enter slug"
                      value={state.slug}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Number of vacancies</label>
                    <input
                      id="numofvacancies"
                      name="numofvacancies"
                      className="form-control"
                      type="number"
                      placeholder="Enter numofvacancies"
                      value={state.numofvacancies}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Company</label>
                    <CreatableSelect
                      autoFocus
                      isClearable
                      isDisabled={isLoading}
                      isLoading={isLoading}
                      styles={customStyles}
                      options={companies}
                      value={selectedCompany}
                      onChange={onCompanyChange}
                      onCreateOption={handleCompanyCreate}
                      placeholder="Select company"
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Location</label>
                    <ReactTags
                      id="location"
                      tags={locationTags}
                      delimiters={delimiters}
                      handleDelete={handleLocationDelete}
                      handleAddition={handleLocationAddition}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Level</label>
                    <ReactTags
                      id="level"
                      tags={levelTags}
                      delimiters={delimiters}
                      handleDelete={handleLevelDelete}
                      handleAddition={handleLevelAddition}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Tag</label>
                    <ReactTags
                      id="tags"
                      tags={tags}
                      delimiters={delimiters}
                      handleDelete={handleTagsDelete}
                      handleAddition={handleTagsAddition}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group clearfix">
                    <label>Is active?</label>
                    <div className="icheck-primary d-inline ustorekit-common-margin-10-side">
                      <input
                        type="checkbox"
                        checked={state.active}
                        id="active"
                        name="active"
                        onChange={handleChange}
                      />
                      <label htmlFor="active"></label>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group clearfix ck-content">
                    <label>Description</label>
                    {/* <textarea
                      className="form-control"
                      id="description"
                      name="description"
                      cols="100"
                      rows="6"
                      defaultValue={description}
                      placeholder="Enter description"
                      onChange={handleChange}
                    ></textarea> */}
                    {/* <MyEditor
                      handleChange={(data) => {
                        setState({ ...state, description: data });
                      }}
                      token={token}
                      path="jobs"
                      data={state.description}
                      {...props}
                    /> */}
                    <MyQuill
                      handleChange={(data) => {
                        setDescription(data);
                        setIsChanged(true);
                      }}
                      data={description}
                      token={token}
                      id="description"
                      name="description"
                    ></MyQuill>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Other Infomations</label>
                    <MyQuill
                      handleChange={(data) => {
                        setOtherinfos(data);
                        setIsChanged(true);
                      }}
                      data={otherinfos}
                      token={token}
                      id="otherinfos"
                      name="otherinfos"
                    ></MyQuill>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Notes</label>
                    <MyQuill
                      handleChange={(data) => {
                        setNotes(data);
                        setIsChanged(true);
                      }}
                      data={notes}
                      token={token}
                      id="notes"
                      name="notes"
                    ></MyQuill>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>JD</label>
                    <FilesUpload
                      getUploadedFiles={getUploadedFiles}
                      getDeleteSavedFile={getDeleteSavedFile}
                      savedFiles={savedFiles}
                      isUpdating={isUpdating}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer">
              {location.state && location.state.id ? (
                <button
                  className="btn btn-info"
                  onClick={updateClicked}
                  disabled={isDisabled}
                >
                  Update
                </button>
              ) : (
                <button
                  className="btn btn-info"
                  onClick={createClicked}
                  disabled={isDisabled}
                >
                  Create
                </button>
              )}
              <button
                className="btn btn-default float-right"
                onClick={() => {
                  if (
                    window.confirm("Are you sure you wish to delete this item?")
                  )
                    onCancel();
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
}

import React, { useState, useEffect, useContext } from "react";
import "../common/custom_css.css";
import AuthContext from "../common/AuthContext";
import { API } from "../common/api-service";
import { useHistory } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";

export default function Payment() {
  const history = useHistory();
  const { authState } = useContext(AuthContext);
  const token = authState.token.slice(1, -1);

  const deleteRow = (id, applicantid) => {
    if (window.confirm("Are you sure you wish to delete this item?")) {
      API.deleteItem("payment", id, token)
        .then((resp1) => {
          console.log(resp1);
        })
        .catch((error) => console.log(error));
      API.updateItem("super_applicants", applicantid, { is_paid: false }, token)
        .then((resp) => {
          API.getItems("payment/", token)
            .then((resp2) => {
              const rows = createRowsList(resp2, columns);
              setState({
                ...state,
                columns: columns,
                rows: rows,
              });
            })
            .catch((error) => console.log(error));
          history.push("/payment/");
        })
        .catch((error) => console.log(error));
    }
  };

  const columns = React.useMemo(
    () => [
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        width: 150,
        cellClassName: "actions",
        getActions: (params) => {
          return [
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={() => deleteRow(params.id, params.row.applicant)}
              color="inherit"
            />,
          ];
        },
      },
      { field: "id", headerName: "ID", width: 50 },
      { field: "applicant", headerName: "Applicant ID", width: 100 },
      { field: "reward", headerName: "Reward", width: 200 },
    ],
    [deleteRow]
  );

  const initialState = {
    columns: [],
    rows: [],
  };
  const [state, setState] = useState(initialState);

  function createRowsList(rows, cols) {
    let validrows = [];
    rows &&
      rows.forEach((row) => {
        let validrow = {};
        cols &&
          cols.forEach((col) => {
            validrow[col.field] = row[col.field];
          });
        validrows.push(validrow);
      });
    return validrows;
  }

  useEffect(() => {
    API.getItems("payment", token)
      .then((resp) => {
        const rows = createRowsList(resp, columns);
        setState({
          ...state,
          columns: columns,
          rows: rows,
        });
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <div>
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Payment</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Payment</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          <div className="card card-default">
            <div className="card-body">
              <div className="relativeWrapper">
                <div className="absoluteWrapper"></div>
                <DataGrid rows={state.rows} columns={columns} />
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

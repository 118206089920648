import React from "react";
import { Link, NavLink } from "react-router-dom";
// import NavLink from "./NavLink";

export default function Menu() {
  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      {/* Brand Logo */}
      <Link to="index3.html" className="brand-link">
        <img
          src={process.env.PUBLIC_URL + "/../dist/img/AdminLTELogo.png"}
          alt="AdminLTE Logo"
          className="brand-image img-circle elevation-3"
          style={{ opacity: ".8" }}
        />
        <span className="brand-text font-weight-light">PancakeHR</span>
      </Link>
      {/* Sidebar */}
      <div className="sidebar">
        {/* Sidebar user panel (optional) */}
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
          <div className="image">
            <img
              src={process.env.PUBLIC_URL + "/../dist/img/user2-160x160.jpg"}
              className="img-circle elevation-2"
              alt="User Image"
            />
          </div>
          <div className="info">
            <Link to="#" className="d-block">
              Alexander Pierce
            </Link>
          </div>
        </div>
        {/* Sidebar Menu */}
        <nav className="mt-2">
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            {/* Add icons to the links using the .nav-icon class
         with font-awesome or any other icon font library */}
            <li className="nav-item">
              <NavLink
                exact
                to="/"
                className="nav-link"
                activeClassName="active"
              >
                <i className="nav-icon fas fa-tachometer-alt" />
                <p>Dashboard</p>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/jobs" className="nav-link" activeClassName="active">
                <i className="nav-icon fas fa-briefcase" />
                <p>Jobs</p>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/candidates"
                className="nav-link"
                activeClassName="active"
              >
                <i className="nav-icon fas fa-users" />
                <p>Candidates</p>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/applicants"
                className="nav-link"
                activeClassName="active"
              >
                <i className="nav-icon fas fa-link" />
                <p>Applicants</p>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/payment"
                className="nav-link"
                activeClassName="active"
              >
                <i className="nav-icon fas fa-dollar-sign"></i>
                <p>Payment</p>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/calendar"
                className="nav-link"
                activeClassName="active"
              >
                <i className="nav-icon fas fa-calendar-alt" />
                <p>
                  Calendar
                  <span className="badge badge-info right">2</span>
                </p>
              </NavLink>
            </li>
            {/* <li className="nav-item has-treeview">
              <NavLink
                to="/mailbox"
                className="nav-link"
                activeClassName="active"
              >
                <i className="nav-icon far fa-envelope" />
                <p>
                  Mailbox
                  <i className="fas fa-angle-left right" />
                </p>
              </NavLink>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <NavLink
                    to="/mailbox/inbox"
                    className="nav-link"
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>Inbox</p>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/mailbox/compose"
                    className="nav-link"
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>Compose</p>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/mailbox/read-mail"
                    className="nav-link"
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>Read</p>
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <NavLink
                to="/contacts"
                className="nav-link"
                activeClassName="active"
              >
                <i className="nav-icon fas fa-address-card" />
                <p>Contacts</p>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/profile"
                className="nav-link"
                activeClassName="active"
              >
                <i className="nav-icon fas fa-user" />
                <p>Profile</p>
              </NavLink>
            </li>
            <li className="nav-header">LABELS</li>
            <li className="nav-item">
              <NavLink
                to="/important"
                className="nav-link"
                activeClassName="active"
              >
                <i className="nav-icon far fa-circle text-danger" />
                <p className="text">Important</p>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/warning"
                className="nav-link"
                activeClassName="active"
              >
                <i className="nav-icon far fa-circle text-warning" />
                <p>Warning</p>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/information"
                className="nav-link"
                activeClassName="active"
              >
                <i className="nav-icon far fa-circle text-info" />
                <p>Informational</p>
              </NavLink>
            </li> */}
          </ul>
        </nav>
        {/* /.sidebar-menu */}
      </div>
      {/* /.sidebar */}
    </aside>
  );
}

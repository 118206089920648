import React, { useState, useEffect, useContext } from "react";
import ReactDataGrid from "react-data-grid";
import { Data, Filters } from "react-data-grid-addons";
// import { Toolbar, Data, Filters } from "react-data-grid-addons";
// import LocationForm from "./LocationForm_backup";
// import ModalComponent from "../modals/ModalComponent";
// import useFetch from "../../hooks/useFetch";
// import StoreContext from "../common/StoreContext";
import AuthContext from "../common/AuthContext";
import { API } from "../common/api-service";
import "../common/custom_css.css";
import { useHistory } from "react-router-dom";
// import { dataCommonFunction } from "../common/data-common-function";

const {
  DraggableHeader: { DraggableContainer },
} = require("react-data-grid-addons");
const selectors = Data.Selectors;
const {
  NumericFilter,
  AutoCompleteFilter,
  MultiSelectFilter,
  SingleSelectFilter,
} = Filters;

const EmptyRowsView = () => {
  const message = "No data to show";
  return (
    <div
      style={{ textAlign: "center", backgroundColor: "#ddd", padding: "100px" }}
    >
      <img src="./logo.png" alt={message} />
      <h3>{message}</h3>
    </div>
  );
};

// const handleFilterChange = (filter) => (filters) => {
//   const newFilters = { ...filters };
//   if (filter.filterTerm) {
//     newFilters[filter.column.key] = filter;
//   } else {
//     delete newFilters[filter.column.key];
//   }
//   return newFilters;
// };

// function getValidFilterValues(rows, columnId) {
//   return rows
//     .map((r) => r[columnId])
//     .filter((item, i, a) => {
//       return i === a.indexOf(item);
//     });
// }

function getRows(rows, filters) {
  return selectors.getRows({ rows, filters });
}

export default function Locations() {
  const history = useHistory();
  // const COLUMN_WIDTH = 250;
  // const { storeState } = useContext(StoreContext);
  const { authState } = useContext(AuthContext);
  const token = authState.token.slice(1, -1);

  // const columns_db = useFetch(
  //   `${process.env.REACT_APP_API_URL}/location_columns/`,
  //   "GET"
  // );
  const columns_db = [
    {
      key: "id",
      name: "ID",
      width: 150,
      editable: true,
      sortable: true,
      filterable: true,
    },
    {
      key: "name",
      name: "Name",
      width: 300,
      editable: true,
      sortable: true,
      filterable: true,
      draggable: true,
    },
  ];
  // const rows_db = useFetch(
  //   `${process.env.REACT_APP_API_URL}/locations/`,
  //   "GET"
  // );

  const initialState = {
    columns: [],
    rows: [],
  };

  // const columns = [
  //   { key: "id", name: "ID", editable: true },
  //   { key: "title", name: "Title", editable: true },
  //   { key: "complete", name: "Complete", editable: true }
  // ];

  // const rows = [
  //   { id: 0, title: "Task 1", complete: 20 },
  //   { id: 1, title: "Task 2", complete: 40 },
  //   { id: 2, title: "Task 3", complete: 60 }
  // ];

  const [selectedIndexes, setSelectedIndexes] = useState([]);
  const [state, setState] = useState(initialState);
  const rowText = selectedIndexes.length === 1 ? "row" : "rows";

  const [filters, setFilters] = useState({});
  const filteredRows = getRows(state.rows, filters);

  // const IDActions = [
  //   {
  //     icon: "fa fa-times",
  //     callback: () => {
  //       alert("Deleting");
  //     },
  //   },
  //   {
  //     icon: "fa fa-edit",
  //     callback: (row) => {
  //       console.log("IDActions row", row);
  //       history.push({
  //         pathname: "/locations/create/",
  //         // state: { id: state.rows[rowIdx].id }
  //       });
  //     },
  //   },
  // ];

  const deleteRow = (id) => {
    if (window.confirm("Are you sure you wish to delete this item?"))
      API.deleteItem("locations", id, token)
        .then((resp) => {
          //   const link =
          //     "locations/?site_id=" + storeState.site_id.toString();
          const link = "locations/";

          API.getItems(link, token)
            .then((resp2) => {
              const rows = createRowsList(resp2, columns_db);
              setState({
                ...state,
                columns: columns_db,
                rows: rows,
              });
            })
            .catch((error) => console.log(error));
          history.push("/jobs/");
        })
        .catch((error) => console.log(error));
  };

  const getCellActions = (column, row) => {
    // const cellActions = {
    //   id: IDActions
    // };
    // return cellActions[column.key];
    if (column.key === "id") {
      return [
        {
          icon: "fa fa-times",
          callback: () => {
            deleteRow(row.id);
          },
        },
        {
          icon: "fa fa-edit",
          callback: () => {
            history.push({
              pathname: "/locations/create/",
              state: { id: row.id },
            });
          },
        },
      ];
    }
  };

  // const onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
  //   setState((state) => {
  //     const rows = state.rows.slice();
  //     for (let i = fromRow; i <= toRow; i++) {
  //       rows[i] = { ...rows[i], ...updated };
  //     }
  //     return { rows };
  //   });
  // };

  // const onRowsSelected = (rows) => {
  //   setSelectedIndexes(selectedIndexes.concat(rows.map((r) => r.rowIdx)));
  // };

  // const onRowsDeselected = (rows) => {
  //   let rowIndexes = rows.map((r) => r.rowIdx);
  //   setSelectedIndexes(
  //     selectedIndexes.filter((i) => rowIndexes.indexOf(i) === -1)
  //   );
  // };

  const onHeaderDrop = (source, target) => {
    const stateCopy = Object.assign({}, state);
    const columnSourceIndex = state.columns.findIndex((i) => i.key === source);
    const columnTargetIndex = state.columns.findIndex((i) => i.key === target);

    stateCopy.columns.splice(
      columnTargetIndex,
      0,
      stateCopy.columns.splice(columnSourceIndex, 1)[0]
    );

    const emptyColumns = Object.assign({}, state, { columns: [] });
    setState(emptyColumns);

    const reorderedColumns = Object.assign({}, state, {
      columns: stateCopy.columns,
    });
    setState(reorderedColumns);
  };

  // const sortRows = (initialRows, sortColumn, sortDirection) => (rows) => {
  //   const comparer = (a, b) => {
  //     if (sortDirection === "ASC") {
  //       return a[sortColumn] > b[sortColumn] ? 1 : -1;
  //     } else if (sortDirection === "DESC") {
  //       return a[sortColumn] < b[sortColumn] ? 1 : -1;
  //     }
  //   };
  //   return sortDirection === "NONE" ? initialRows : [...rows].sort(comparer);
  // };

//   function createColumnsList(cols) {
//     console.log("cols createColumnsList", cols);
//     let columns = [];
//     cols &&
//       cols.forEach((element) => {
//         let column = "";
//         if (element.slug == "name") {
//           column = {
//             key: element.slug,
//             name: element.name,
//             width: COLUMN_WIDTH,
//             editable: true,
//             sortable: true,
//             filterable: true,
//             frozen: true,
//           };
//         } else {
//           column = {
//             key: element.slug,
//             name: element.name,
//             width: COLUMN_WIDTH,
//             editable: true,
//             resizable: true,
//             sortable: true,
//             filterable: true,
//             draggable: true,
//           };
//         }
//         columns.push(column);
//       });
//     return columns;
//   }

  function createRowsList(rows, cols) {
    let validrows = [];
    rows &&
      rows.forEach((row) => {
        let validrow = {};
        cols &&
          cols.forEach((col) => {
            validrow[col.key] = row[col.key];
            validrow["id"] = row["id"];
          });
        validrows.push(validrow);
      });
    return validrows;
  }

  const newRow = () => {
    // history.push("/locations/create/");
    history.push({
      pathname: "/locations/create/",
      //   state: { storeid: storeState.site_id },
    });
  };

  // const udpatedRow = (row) => {
  //   const newRows = state.rows.map((r) => {
  //     if (r.id === row.id) {
  //       return row;
  //     }
  //     return r;
  //   });
  //   setState({
  //     ...state,
  //     rows: newRows,
  //   });
  // };

  // const rowCreated = (row) => {
  //   const newRows = [...state.rows, row];
  //   setState({
  //     ...state,
  //     rows: newRows,
  //   });
  // };

  function createRowsList(rows, cols) {
    let validrows = [];
    rows &&
      rows.forEach((row) => {
        let validrow = {};
        cols &&
          cols.forEach((col) => {
            validrow[col.key] = row[col.key];
          });
        validrows.push(validrow);
      });
    return validrows;
  }

  useEffect(() => {
    // dataCommonFunction
    //   .getLinkByStoreID("locations", storeState, token)
    //   .then((link) => {
    //     API.getItems(link, token)
    //       .then((resp) => {
    //         const rows = createRowsList(resp, columns_db);
    //         setState({
    //           ...state,
    //           columns: columns_db,
    //           rows: rows,
    //         });
    //       })
    //       .catch((error) => console.log(error));
    //   })
    //   .catch((error) => console.log(error));
    API.getItems("locations", token)
      .then((resp) => {
        const rows = createRowsList(resp, columns_db);
        setState({
          ...state,
          columns: columns_db,
          rows: rows,
        });
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <div>
      <div className="toolbar">
        <div className="ustorekit-common-margin-10">
          <button
            className="cell-button btn btn-sm btn-success"
            onClick={newRow}
          >
            Add Row
          </button>
        </div>
      </div>
      <span>
        {selectedIndexes.length} {rowText} selected
      </span>
      <div className="relativeWrapper">
        <div className="absoluteWrapper"></div>
        <DraggableContainer onHeaderDrop={onHeaderDrop}>
          <ReactDataGrid
            rowKey="id"
            columns={state.columns}
            rowGetter={(i) => filteredRows[i]}
            rowsCount={filteredRows.length}
            minHeight={500}
            getCellActions={getCellActions}
            // onGridRowsUpdated={onGridRowsUpdated}
            // enableCellSelect={true}
            emptyRowsView={EmptyRowsView}
            // onGridSort={(sortColumn, sortDirection) =>
            //   setState([
            //     ...state,
            //     { rows: sortRows(state.rows, sortColumn, sortDirection) },
            //   ])
            // }
            // rowSelection={{
            //   showCheckbox: true,
            //   enableShiftSelect: true,
            //   onRowsSelected: onRowsSelected,
            //   onRowsDeselected: onRowsDeselected,
            //   selectBy: {
            //     indexes: selectedIndexes,
            //   },
            // }}
            // toolbar={<Toolbar enableFilter={true} />}
            // onAddFilter={(filter) => setFilters(handleFilterChange(filter))}
            // onClearFilters={() => setFilters({})}
            // getValidFilterValues={(columnKey) =>
            //   getValidFilterValues(state.rows, columnKey)
            // }
          />
        </DraggableContainer>
      </div>
      <div></div>
    </div>
  );
}

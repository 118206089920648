import React, { useReducer } from "react";
import Jobs from "./Jobs";
// import StoreContext from "../common/StoreContext";
import Companies from "./Companies";
import Locations from "./Locations";
import Levels from "./Levels";
// import StoreSelection from "./StoreSelection";
// import { useHistory } from "react-router-dom";

// const initialState = {
//   site_id: localStorage.getItem("site_id") || null,
// };

// const reducer = (storeState, action) => {
//   switch (action.type) {
//     case "STORE_CHANGED":
//       localStorage.setItem("site_id", JSON.stringify(action.payload));
//       console.log("action.payload", action.payload);
//       return {
//         ...storeState,
//         site_id: action.payload,
//       };
//     default:
//       return storeState;
//   }
// };

export default function JobsHome() {
  //   const history = useHistory();
  //   const [storeState, dispatch] = useReducer(reducer, initialState);
  //   const selectStore = {
  //     margin: "30px 0px 30px 15px",
  //   };

  return (
    // <StoreContext.Provider
    //   value={{
    //     storeState,
    //     dispatch,
    //   }}
    // >
    <div>
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Jobs</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Jobs</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          <div className="card card-default">
            {/* <div className="col-sm-6" style={selectStore}>
                <StoreSelection />
              </div> */}
            <div className="card-body">
              <ul
                className="nav nav-tabs"
                id="custom-content-above-tab"
                role="tablist"
              >
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="custom-content-above-jobs-tab"
                    data-toggle="pill"
                    href="#custom-content-above-jobs"
                    role="tab"
                    aria-controls="custom-content-above-jobs"
                    aria-selected="true"
                  >
                    Jobs
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-content-above-companies-tab"
                    data-toggle="pill"
                    href="#custom-content-above-companies"
                    role="tab"
                    aria-controls="custom-content-above-companies"
                    aria-selected="false"
                  >
                    Companies
                  </a>
                </li>
              </ul>
              <div className="tab-content" id="custom-content-above-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="custom-content-above-jobs"
                  role="tabpanel"
                  aria-labelledby="custom-content-above-jobs-tab"
                >
                  <div className="ustorekit-common-margin-30">
                    <Jobs />
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="custom-content-above-companies"
                  role="tabpanel"
                  aria-labelledby="custom-content-above-companies-tab"
                >
                  <div className="ustorekit-common-margin-30">
                    <Companies />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    // </StoreContext.Provider>
  );
}

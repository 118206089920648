import React from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import Html from "react-pdf-html";

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    margin: 30,
  },
  text: {
    marginBottom: 10,
    fontSize: 11,
    textAlign: "left",
  },
  html: {
    marginRight: 300,
    fontSize: 11,
    textAlign: "left",
    width: "100%",
  },
  title: {
    fontSize: 15,
    textAlign: "center",
    marginBottom: 30,
    fontWeight: "bold",
  },
});

export default function JobDescription(props) {
  const job = props.job;

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text style={styles.title}>{job.title}</Text>
        <Text style={styles.text}>Working Location: {job.location}</Text>
        <Text style={styles.text}>Salary: {job.salary}</Text>
        <Text style={styles.text}>Levels: {job.level}</Text>
        <Text style={styles.text}>Roles and Skills: {job.tags}</Text>
        <Html style={styles.html}>{job.description}</Html>
      </Page>
    </Document>
  );
}

import React, { useState, useEffect, useContext } from "react";
import "../common/custom_css.css";
import AuthContext from "../common/AuthContext";
import { API } from "../common/api-service";
import { commonFunction } from "../common/common-functions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddIcon from "@mui/icons-material/Add";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";

export default function FindCandidatesBox({
  job_id,
  refreshAppliedCandidates,
}) {
  // const job_id = props.job_id;
  const { authState } = useContext(AuthContext);
  const token = authState.token.slice(1, -1);

  const renderTags = (tag) => {
    return (
      <button className="btn btn-secondary btn-xs tags-margin" key={tag.text}>
        {tag.text}
      </button>
    );
  };

  const getCandidatesByJob = () => {
    var link = "candidates/find_candidates_by_job/" + job_id.toString() + "/";
    API.getItems(link, token).then((resp) => {
      const rows = createRowsList(resp.candidates, candidate_columns);
      setCandidates({
        ...candidates,
        columns: candidate_columns,
        rows: rows,
      });
    });
  };

  const apply_candidate = (row_id) => {
    // Check if candidate applicated
    var link =
      "candidates/applied_candidate_for_job/" + job_id + "/" + row_id + "/";
    API.getItems(link, token).then((resp1) => {
      if (resp1.applicants.length <= 0) {
        // TO DO: check if candidate active or not
        var data = {
          job: job_id,
          candidate: row_id,
          status: "1",
          active: true,
        };
        API.createItem("applicants", data, token)
          .then((resp) => {
            var content =
              "Candidate ID: " +
              resp.candidate.toString() +
              " applied Job ID: " +
              resp.job.toString();
            API.createItem(
              "applicant_histories",
              { applicant: resp.id, content: content },
              token
            )
              .then((resp2) => {
                console.log(resp2);
              })
              .catch((error) => {
                console.log(error);
                toast.error(error);
              });
            toast.success("Application successful");
            getCandidatesByJob();
            // props.refreshAppliedCandidates();
            refreshAppliedCandidates();
          })
          .catch((error) => {
            console.log(error);
            toast.error(error);
          });
      }
    });
  };

  const candidate_columns = React.useMemo(
    () => [
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        width: 150,
        cellClassName: "actions",
        getActions: (params) => {
          return [
            <GridActionsCellItem
              icon={<AddIcon />}
              label="Add"
              onClick={() => apply_candidate(params.id)}
              color="inherit"
            />,
          ];
        },
      },
      { field: "id", headerName: "ID", width: 50 },
      { field: "name", headerName: "Name", width: 250 },
      {
        field: "location",
        headerName: "Location",
        width: 200,
        renderCell: (cellValues) => {
          var tags = cellValues.row.location;
          if (tags && tags.length != 0) {
            return (
              <>
                {tags &&
                  tags.map((tag, i) => {
                    return renderTags(tag);
                  })}
              </>
            );
          } else {
            return <></>;
          }
        },
      },
      { field: "current_position", headerName: "Current Position", width: 100 },
      { field: "dob", headerName: "Date of birth", width: 100 },
      { field: "mobile", headerName: "Mobile", width: 100 },
      { field: "email", headerName: "Email", width: 150 },
      { field: "active", headerName: "Is active?", width: 100 },
    ],
    [apply_candidate]
  );

  const initialState = {
    columns: [],
    rows: [],
  };

  const [candidates, setCandidates] = useState(initialState);

  function createRowsList(rows, cols) {
    let validrows = [];
    rows &&
      rows.forEach((row) => {
        let validrow = {};
        cols &&
          cols.forEach((col) => {
            switch (col.field) {
              case "location":
                if (row[col.field]) {
                  var tags = commonFunction.splitStrToTags(row[col.field]);
                  validrow[col.field] = tags;
                } else {
                  validrow[col.field] = [];
                }
                break;
              case "active":
                if (row[col.field]) {
                  validrow[col.field] = "✔";
                } else {
                  validrow[col.field] = "×";
                }
                break;
              //   case "gender":
              //     if (row[col.field] === "M") {
              //       validrow[col.field] = "Male";
              //     } else {
              //       validrow[col.field] = "Female";
              //     }
              //     break;
              default:
                validrow[col.field] = row[col.field];
                validrow["id"] = row["id"];
            }
          });
        validrows.push(validrow);
      });
    return validrows;
  }

  useEffect(() => {
    if (job_id) {
      getCandidatesByJob();
    }
    // window.scrollTo(0, 0);
  }, [job_id]);

  return (
    <React.Fragment>
      <DataGrid rows={candidates.rows} columns={candidate_columns} />
      <ToastContainer />
    </React.Fragment>
  );
}

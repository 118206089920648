import React, { useReducer } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AuthContext from "./components/common/AuthContext";
import Login from "./components/accounts/Login";
import MainLayout from "./components/layout/MainLayout";
import Dashboard from "./components/dashboard/Dashboard";
import JobsHome from "./components/jobs/JobsHome";
import JobForm from "./components/jobs/JobForm";
import JobDetail from "./components/jobs/JobDetail";
import CompanyDetail from "./components/jobs/CompanyDetail";
import CompanyForm from "./components/jobs/CompanyForm";
import NotFound from "./components/common/NotFound";
import Homepage from "./components/homepage/Homepage";
import Candidates from "./components/candidates/Candidates";
import CandidateForm from "./components/candidates/CandidateForm";
import CandidateDetail from "./components/candidates/CandidateDetail";
import FindCandidatesBox from "./components/applicants/FindCandidatesBox";
import FindJobsBox from "./components/applicants/FindJobsBox";
import Applicants from "./components/applicants/Applicants";
import ApplicantDetail from "./components/applicants/ApplicantDetail";
import Payment from "./components/payment/Payment";

const initialState = {
  isAuthenticated: localStorage.getItem('isAuthenticated') || false,
  // user: null,
  token: localStorage.getItem('token') || null,
};
const reducer = (authState, action) => {
  switch (action.type) {
    case "LOGIN":
      // localStorage.setItem("user", JSON.stringify(action.payload.user));
      localStorage.setItem("token", JSON.stringify(action.payload.token));
      localStorage.setItem("isAuthenticated", true);
      console.log("action.payload.token", action.payload.token);
      return {
        ...authState,
        isAuthenticated: true,
        // user: action.payload.user,
        token: action.payload.token,
      };
    case "LOGOUT":
      localStorage.clear();
      return {
        ...authState,
        isAuthenticated: false,
        //user: null,
      };
    default:
      return authState;
  }
};

export default function App() {
  const [authState, dispatch] = useReducer(reducer, initialState);

  return (
    <AuthContext.Provider
      value={{
        authState,
        dispatch,
      }}
    >
      <Router className="App">
        <Switch>
          {authState.isAuthenticated ? (
            <MainLayout>
              <Route path="/" component={Dashboard} exact />
              <Route path="/accounts/login/" component={Dashboard} exact />
              <Route path="/jobs" component={JobsHome} exact />
              <Route path="/jobs/create/" component={JobForm} exact />
              <Route path="/jobs/detail/" component={JobDetail} exact/>
              <Route path="/companies/detail/" component={CompanyDetail} exact/>
              <Route path="/companies/create/" component={CompanyForm} exact/>
              <Route path="/candidates/" component={Candidates} exact />
              <Route path="/candidates/detail/" component={CandidateDetail} exact/>
              <Route path="/candidates/create/" component={CandidateForm} exact/>
              <Route path="/applicants/" component={Applicants} exact />
              <Route path="/applicants/find_candidates/" component={FindCandidatesBox} exact/>
              <Route path="/applicants/find_jobs/" component={FindJobsBox} exact/>
              <Route path="/applicants/detail/" component={ApplicantDetail} exact/>
              <Route path="/payment/" component={Payment} exact />
            </MainLayout>
          ) : (
            <div>
              <Route path="/" component={Homepage} exact />
              <Route path="/accounts/login/" component={Login} exact />
              <Route path="/jobs" component={Homepage} />
            </div>
          )}
          <Route component={NotFound} />
        </Switch>
      </Router>
    </AuthContext.Provider>
  );
}

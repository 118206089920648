import React from "react";

export default function RegisterForm(props) {
  return (
    <React.Fragment>
      <div>
        <p className="login-box-msg">Register a new membership</p>
        <form onSubmit={props.handleFormSubmit}>
          <div className="input-group mb-3">
            <input
              className="form-control"
              type="text"
              name="username"
              value={props.data.username}
              onChange={props.handleInputChange}
              placeholder="Enter username"
            />
            <div className="input-group-append">
              <div className="input-group-text">
                <span className="fas fa-user" />
              </div>
            </div>
          </div>
          <div className="input-group mb-3">
            <input
              className="form-control"
              type="email"
              name="email"
              value={props.data.email}
              onChange={props.handleInputChange}
              placeholder="Enter email"
            />
            <div className="input-group-append">
              <div className="input-group-text">
                <span className="fas fa-envelope" />
              </div>
            </div>
          </div>
          <div className="input-group mb-3">
            <input
              className="form-control"
              type="password"
              name="password"
              value={props.data.password}
              onChange={props.handleInputChange}
              placeholder="Enter password"
            />
            <div className="input-group-append">
              <div className="input-group-text">
                <span className="fas fa-lock" />
              </div>
            </div>
          </div>
          <div className="input-group mb-3">
            <input
              className="form-control"
              type="password"
              name="password2"
              value={props.data.password2}
              onChange={props.handleInputChange}
              placeholder="Retype password"
            />
            <div className="input-group-append">
              <div className="input-group-text">
                <span className="fas fa-lock" />
              </div>
            </div>
          </div>
          {props.data.errorMessage && (
            <span className="form-error">{props.data.errorMessage}</span>
          )}
          <div className="row">
            <div className="col-8">
              <div className="icheck-primary">
                <input
                  type="checkbox"
                  id="agreeTerms"
                  name="terms"
                  defaultValue="agree" 
                  checked={props.data.agreeTerms}
                  onChange={props.updateCheckBox}
                />
                <label htmlFor="agreeTerms">
                  I agree to the <a href="#">terms</a>
                </label>
              </div>
            </div>
            {/* /.col */}
            <div className="col-4">
              <button
                className="btn btn-primary btn-block"
                disabled={props.data.isSubmitting}
              >
                {props.data.isSubmitting ? "Loading..." : "Register"}
              </button>
            </div>
            {/* /.col */}
          </div>
        </form>
      </div>
    </React.Fragment>
  );
}

import React, { useState, useEffect, useContext } from "react";
import { API } from "../common/api-service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthContext from "../common/AuthContext";
import { useHistory, useLocation } from "react-router-dom";
import Tooltip from "../common/Tooltip";
import { commonFunction } from "../common/common-functions";
import { commonVariables } from "../common/common-variables";
import { Link } from "react-router-dom";
import Select from "react-select";
import FilesUpload from "../common/FilesUpload";
import FileUploadService from "../common/FileUploadService";
import Moment from "moment";
import ReactHtmlParser from "react-html-parser";
import SlideDrawer from "../SlideDrawer/SlideDrawer";
import Backdrop from "../SlideDrawer/Backdrop";
import Files from "./ApplicantDetail/Files";
import { PDFDownloadLink } from "@react-pdf/renderer";
import JobDescription from "../jobs/JobDescription";

// For react-dropzone
const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "relative",
  width: "auto",
  height: "100%",
};

const thumbName = {
  width: 100,
};

export default function ApplicantDetail() {
  const history = useHistory();
  const location = useLocation();

  const { authState } = useContext(AuthContext);
  const token = authState.token.slice(1, -1);

  const initialState = {
    id: "",
    job: {},
    candidate: {},
    status: "",
    interview_date: "",
    notes: "",
    is_paid: false,
  };
  const [state, setState] = useState(initialState);

  // Job Detail
  const [selectedCompany, setSelectedCompany] = useState({});
  const [savedJobFiles, setSavedJobFiles] = useState([]);
  const [jobLocationTags, setJobLocationTags] = useState([]);
  const [jobLevelTags, setJobLevelTags] = useState([]);

  // Candidate Detail
  const [savedCandidateFiles, setSavedCandidateFiles] = useState([]);
  const [candidateLocationTags, setCandidateLocationTags] = useState([]);

  // Others
  const [isDisabled, setIsDisabled] = useState(true);
  const [isNoteDisabled, setIsNoteDisabled] = useState(true);
  const [isPaidDisabled, setIsPaidDisabled] = useState(false);

  // Applicant Files
  const [files, setFiles] = useState([]);
  const [savedFiles, setSavedFiles] = useState([]);
  const [savedFileNames, setSavedFileNames] = useState([]);
  const [deleteSavedFiles, setDeleteSavedFiles] = useState([]);
  const [progress, setProgress] = useState(0);

  // Applicant History
  const [histories, setHistories] = useState([]);

  // Sliding Panel
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [document, setDocument] = useState("");

  const drawerToggleClickHandler = () => {
    setDrawerOpen(!drawerOpen);
  };

  const backdropClickHandler = () => {
    setDrawerOpen(false);
  };

  let backdrop;
  if (drawerOpen) {
    backdrop = <Backdrop close={() => backdropClickHandler()} />;
  }

  const handleFileClick = (preview) => {
    setDocument(preview);
    drawerToggleClickHandler();
  };

  // Job Files
  const savedJobThumbs =
    savedJobFiles &&
    savedJobFiles.map((file, i) => {
      return (
        <div key={file.name}>
          <div style={thumb}>
            <div style={thumbInner}>
              <a
                href="javascript:void(0)"
                onClick={() => handleFileClick(file.preview)}
              >
                <img src={file.preview} style={img} />
              </a>
            </div>
          </div>
          <div style={thumbName}>
            <a
              href="javascript:void(0)"
              onClick={() => handleFileClick(file.preview)}
            >
              {file.name}
            </a>
          </div>
        </div>
      );
    });

  // Candidate Files
  const savedCandidateThumbs =
    savedCandidateFiles &&
    savedCandidateFiles.map((file, i) => {
      return (
        <div key={file.name}>
          <div style={thumb}>
            <div style={thumbInner}>
              <a
                href="javascript:void(0)"
                onClick={() => handleFileClick(file.preview)}
              >
                <img src={file.preview} style={img} />
              </a>
            </div>
          </div>
          <div style={thumbName}>
            <a
              href="javascript:void(0)"
              onClick={() => handleFileClick(file.preview)}
            >
              {file.name}
            </a>
          </div>
        </div>
      );
    });

  // Job Location Tags
  const displayJobLocationTags =
    jobLocationTags &&
    jobLocationTags.map((tag, i) => {
      return (
        <button
          className="btn btn-info btn-xs ustorekit-common-margin-10-right"
          key={tag.text}
        >
          {tag.text}
        </button>
      );
    });

  const displayJobLevelTags =
    jobLevelTags &&
    jobLevelTags.map((tag, i) => {
      return (
        <button
          className="btn btn-info btn-xs ustorekit-common-margin-10-right"
          key={tag.text}
        >
          {tag.text}
        </button>
      );
    });

  // Candidate Location Tags
  const displayCandidateLocationTags =
    candidateLocationTags &&
    candidateLocationTags.map((tag, i) => {
      return (
        <button
          className="btn btn-info btn-xs ustorekit-common-margin-10-right"
          key={tag.text}
        >
          {tag.text}
        </button>
      );
    });

  const handleChange = (evt) => {
    const value =
      evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
    if (evt.target.type === "textarea") {
      setIsNoteDisabled(false);
    } else {
      setIsDisabled(false);
    }
  };

  const onFindJobs = () => {
    history.push({
      pathname: "/candidates/detail/",
      state: { id: state.candidate.id, hasFindJobs: true },
    });
  };
  const onFindCandidates = () => {
    history.push({
      pathname: "/jobs/detail/",
      state: { id: state.job.id, hasFindCandidates: true },
    });
  };

  const onStatusChange = (newValue, actionMeta) => {
    setState({
      ...state,
      status: newValue,
    });
    setIsDisabled(false);
    console.groupEnd();
  };

  const handleUpdateStatusAndInterview = () => {
    if (location.state && !isDisabled) {
      var body = {};
      if (state.interview_date != null) {
        body = {
          status: state.status.value,
          interview_date: state.interview_date,
          is_paid: state.is_paid,
        };
      } else {
        body = {
          status: state.status.value,
          is_paid: state.is_paid,
        };
      }

      API.updateItem("super_applicants", location.state.id, body, token)
        .then((resp) => {
          var content =
            "Changed status to: " +
            resp.status +
            ". And interview date to: " +
            Moment(resp.interview_date).format("MM-DD-YYYY, HH:MM");
          if (resp.is_paid) {
            var payment_data = { applicant: resp.id, reward: resp.job.reward };
            API.createItem("payment", payment_data, token)
              .then((resp3) => {
                setIsPaidDisabled(resp3.is_paid);
              })
              .catch((error) => {
                console.log(error);
                toast.error(error);
              });
            content = content + ". Payment added.";
          }
          API.createItem(
            "applicant_histories",
            { applicant: resp.id, content: content },
            token
          )
            .then((resp1) => {
              console.log(resp1);
            })
            .catch((error) => {
              console.log(error);
              toast.error(error);
            });
          var link = "applicanthistories/" + resp.id.toString() + "/";
          API.getItems(link, token).then((resp2) => {
            setHistories(resp2.histories);
          });
          setIsDisabled(true);
          toast.success(content);
        })
        .catch((error) => {
          console.log(error);
          toast.error(error);
        });
    }
  };

  const handleUpdateNotes = () => {
    if (location.state && !isNoteDisabled) {
      API.updateItem(
        "super_applicants",
        location.state.id,
        { notes: state.notes },
        token
      )
        .then((resp) => {
          var content = "Added notes";
          API.createItem(
            "applicant_histories",
            { applicant: resp.id, content: content },
            token
          )
            .then((resp1) => {
              console.log(resp1);
            })
            .catch((error) => {
              console.log(error);
              toast.error(error);
            });
          var link = "applicanthistories/" + resp.id.toString() + "/";
          API.getItems(link, token).then((resp2) => {
            setHistories(resp2.histories);
          });
          setIsNoteDisabled(true);
          toast.success("Notes are updated.");
        })
        .catch((error) => {
          console.log(error);
          toast.error(error);
        });
    }
  };

  // Handle Applicant Files Upload
  const getUploadedFiles = (list) => {
    setFiles(list);
  };

  const getDeleteSavedFile = (file) => {
    var files = deleteSavedFiles;
    files.push(file);
    setDeleteSavedFiles(files);
  };

  const handleUploadFiles = () => {
    setProgress(0);
    FileUploadService.upload(
      "applicant_files",
      files,
      token,
      "applicant",
      state.id,
      (event) => {
        const percentage = Math.round((100 * event.loaded) / event.total);
        setProgress(percentage);
        if (percentage == 100) {
          setFiles([]);
        }
      }
    );
    var link = "applicantfiles/" + state.id.toString() + "/";
    API.getItems(link, token).then((resp4) => {
      var old_files = resp4.files;
      var new_files = [];
      for (var i = 0, l = old_files.length; i < l; i++) {
        new_files.push({
          id: old_files[i].id,
          name: old_files[i].name,
          preview: old_files[i].file,
        });
      }
      setSavedFileNames(new_files);
      setSavedFiles(resp4.files);
    });
    var content = "Upload file: ";
    for (var i = 0; i < files.length; i++) {
      content = content + files[i].name + "; ";
    }
    content = content.slice(0, -2);
    API.createItem(
      "applicant_histories",
      { applicant: state.id, content: content },
      token
    )
      .then((resp1) => {
        console.log(resp1);
      })
      .catch((error) => {
        console.log(error);
        toast.error(error);
      });
    var link = "applicanthistories/" + state.id.toString() + "/";
    API.getItems(link, token).then((resp2) => {
      setHistories(resp2.histories);
    });
    toast.success("Uploading is finished.");
  };

  useEffect(() => {
    if (location.state) {
      if (location.state.id) {
        API.getItem("super_applicants", location.state.id, token)
          .then((resp) => {
            var option = commonVariables.APPLICANT_STATUS.find((element) => {
              return element.value === resp.status;
            });
            setState({
              ...state,
              id: resp.id,
              job: resp.job,
              candidate: resp.candidate,
              status: option,
              interview_date:
                resp.interview_date != null
                  ? resp.interview_date.slice(0, 16).toString()
                  : null,
              notes: resp.notes,
              is_paid: resp.is_paid,
            });

            if (!resp.job.active || !resp.candidate.active) {
              toast.error("Job or Candidate is non active.");
            }

            setIsPaidDisabled(resp.is_paid);

            // Applicant Status
            // setApplicantStatus(option);
            // setInterviewDate(resp.interview_date);

            // Job Infos
            setJobLocationTags(
              commonFunction.splitStrToTags(resp.job.location)
            );
            setJobLevelTags(commonFunction.splitStrToTags(resp.job.level));

            API.getItem("companies", resp.job.company, token).then((resp1) => {
              setSelectedCompany(resp1);
            });
            var link = "jobfiles/" + resp.job.id.toString() + "/";
            API.getItems(link, token).then((resp2) => {
              var old_files = resp2.files;
              var new_files = [];
              for (var i = 0, l = old_files.length; i < l; i++) {
                new_files.push({
                  id: old_files[i].id,
                  job: old_files[i].job,
                  name: old_files[i].name,
                  size: old_files[i].size,
                  lastModifiedDate: old_files[i].lastModifiedDate,
                  preview: old_files[i].file,
                });
              }
              setSavedJobFiles(new_files);
            });

            // Candidate Infos
            setCandidateLocationTags(
              commonFunction.splitStrToTags(resp.candidate.location)
            );

            var link = "candidatefiles/" + resp.candidate.id.toString() + "/";
            API.getItems(link, token).then((resp3) => {
              var old_files = resp3.files;
              var new_files = [];
              for (var i = 0, l = old_files.length; i < l; i++) {
                new_files.push({
                  id: old_files[i].id,
                  candidate: old_files[i].candidate,
                  name: old_files[i].name,
                  size: old_files[i].size,
                  lastModifiedDate: old_files[i].lastModifiedDate,
                  preview: old_files[i].file,
                });
              }
              setSavedCandidateFiles(new_files);
            });

            var link = "applicantfiles/" + resp.id.toString() + "/";
            API.getItems(link, token).then((resp4) => {
              var old_files = resp4.files;
              var new_files = [];
              for (var i = 0, l = old_files.length; i < l; i++) {
                new_files.push({
                  id: old_files[i].id,
                  name: old_files[i].name,
                  preview: old_files[i].file,
                });
              }
              setSavedFileNames(new_files);
              setSavedFiles(resp4.files);
            });

            var link = "applicanthistories/" + resp.id.toString() + "/";
            API.getItems(link, token).then((resp5) => {
              setHistories(resp5.histories);
            });

            setIsDisabled(true);
            setIsNoteDisabled(true);
          })
          .catch((error) => {
            console.log(error);
            toast.error(error);
          });
      }
    }
  }, []);
  return (
    <React.Fragment>
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Applicant Detail</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/applicants/">Applicants</a>
                  </li>
                  <li className="breadcrumb-item active">Applicant Detail</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-3">
                <div className="card card-primary card-outline">
                  <div className="card-body box-profile">
                    <h3 className="profile-username text-center">
                      {"ID: "}
                      {state.id}
                    </h3>

                    <p className="text-muted text-center">
                      {state.candidate.name}
                      {" - "}
                      {state.job.title}
                    </p>

                    <ul className="list-group list-group-unbordered mb-3">
                      <li className="list-group-item">
                        <b>Status</b>{" "}
                        <Select
                          className="basic-single selectbox-container"
                          classNamePrefix="select"
                          value={state.status}
                          name="applicantStatus"
                          options={commonVariables.APPLICANT_STATUS}
                          onChange={onStatusChange}
                        />
                      </li>
                      <li className="list-group-item">
                        <b>Interview</b>{" "}
                        <input
                          id="interview_date"
                          name="interview_date"
                          className="form-control"
                          type="datetime-local"
                          placeholder="Enter interview date"
                          value={state.interview_date}
                          onChange={handleChange}
                        />
                      </li>
                      <li className="list-group-item">
                        <b>Is paid</b>{" "}
                        <input
                          type="checkbox"
                          checked={state.is_paid}
                          id="is_paid"
                          name="is_paid"
                          onChange={handleChange}
                          disabled={isPaidDisabled}
                        />
                      </li>
                    </ul>
                    <button
                      className="btn btn-primary btn-block"
                      disabled={isDisabled}
                      onClick={handleUpdateStatusAndInterview}
                    >
                      <b>Change</b>
                    </button>

                    <div className="text-center ustorekit-common-above-margin-10">
                      <a className="btn btn-app" onClick={() => onFindJobs()}>
                        <i className="fas fa-briefcase"></i> Jobs
                      </a>
                      <a className="btn btn-app" onClick={() => onFindCandidates()}>
                        <i className="fas fa-users"></i> Candidates
                      </a>
                    </div>
                  </div>
                </div>

                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Notes</h3>
                  </div>
                  <div className="card-body">
                    <div className="ustorekit-common-under-margin-10">
                      <textarea
                        className="form-control"
                        id="notes"
                        name="notes"
                        cols="100"
                        rows="6"
                        defaultValue={state.notes}
                        placeholder="Enter notes"
                        onChange={handleChange}
                      ></textarea>
                    </div>
                    <button
                      className="btn btn-primary btn-block"
                      disabled={isNoteDisabled}
                      onClick={handleUpdateNotes}
                    >
                      <b>Change</b>
                    </button>
                  </div>
                </div>
                {/* Files */}
                <Files
                  savedFileNames={savedFileNames}
                  handleFileClick={handleFileClick}
                />
              </div>
              <div className="col-md-9">
                <div className="card">
                  <div className="card-header p-2">
                    <ul className="nav nav-pills">
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          href="#job_detail"
                          data-toggle="tab"
                        >
                          Job Detail
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="#candidate_detail"
                          data-toggle="tab"
                        >
                          Candidate Detail
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="#upload_files"
                          data-toggle="tab"
                        >
                          Upload Files
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="#history"
                          data-toggle="tab"
                        >
                          History
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="card-body">
                    <div className="tab-content">
                      <div className="active tab-pane" id="job_detail">
                        <div className="text-muted">
                          <p className="text-sm">
                            <b className="d-block">ID</b>
                            {state.job.id}
                          </p>
                          <div className="text-sm">
                            <b className="d-block">Company</b>
                            <Tooltip text={selectedCompany.description}>
                              <Link
                                to={{
                                  pathname: "/companies/detail/",
                                  state: { id: selectedCompany.id },
                                }}
                              >
                                {selectedCompany.name}
                              </Link>
                            </Tooltip>
                          </div>
                          <p className="text-sm">
                            <b className="d-block">Location</b>
                            {displayJobLocationTags}
                          </p>
                          <p className="text-sm">
                            <b className="d-block">Level</b>
                            {displayJobLevelTags}
                          </p>
                          <p className="text-sm">
                            <b className="d-block">Is active?</b>
                            {state.job.active ? "Yes" : "No"}
                          </p>
                          <p className="text-sm">
                            <b className="d-block">Description</b>
                            {ReactHtmlParser(state.job.description)}
                          </p>
                          <p className="text-sm">
                            <b className="d-block">Other Infomations</b>
                            {ReactHtmlParser(state.job.otherinfos)}
                          </p>
                          <p className="text-sm">
                            <b className="d-block">Notes</b>
                            {ReactHtmlParser(state.job.notes)}
                          </p>
                          <div className="text-sm">
                            <b className="d-block">Job files</b>
                            <aside style={thumbsContainer}>
                              {savedJobThumbs}
                            </aside>
                          </div>
                          <div className="ustorekit-common-above-margin-10">
                            <PDFDownloadLink
                              document={<JobDescription job={state.job} />}
                              fileName={state.job.title + ".pdf"}
                            >
                              {({ blob, url, loading, error }) =>
                                loading ? (
                                  <button className="btn btn-sm btn-primary ustorekit-common-margin-10-right">
                                    Loading JD...
                                  </button>
                                ) : (
                                  <button className="btn btn-sm btn-primary ustorekit-common-margin-10-right">
                                    Download JD
                                  </button>
                                )
                              }
                            </PDFDownloadLink>
                            <button
                              className="btn btn-danger"
                              onClick={() => onFindCandidates()}
                            >
                              Find more candidates
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="tab-pane" id="candidate_detail">
                        <div className="text-muted">
                          <p className="text-sm">
                            <b className="d-block">ID</b>
                            {state.candidate.id}
                          </p>
                          <p className="text-sm">
                            <b className="d-block">Location</b>
                            {displayCandidateLocationTags}
                          </p>
                          <p className="text-sm">
                            <b className="d-block">Gender</b>
                            {state.candidate.gender == "M" ? "Male" : "Female"}
                          </p>
                          <p className="text-sm">
                            <b className="d-block">Date of birth</b>
                            {state.candidate.dob}
                          </p>
                          <p className="text-sm">
                            <b className="d-block">Current position</b>
                            {state.candidate.current_position}
                          </p>
                          <p className="text-sm">
                            <b className="d-block">Mobile</b>
                            {state.candidate.mobile}
                          </p>
                          <p className="text-sm">
                            <b className="d-block">Email</b>
                            {state.candidate.email}
                          </p>
                          <p className="text-sm">
                            <b className="d-block">Address</b>
                            {state.candidate.address}
                          </p>
                          <p className="text-sm">
                            <b className="d-block">Social link</b>
                            {state.candidate.social_link}
                          </p>
                          <p className="text-sm">
                            <b className="d-block">Is active?</b>
                            {state.candidate.active ? "Yes" : "No"}
                          </p>
                          <p className="text-sm">
                            <b className="d-block">Note</b>
                            {ReactHtmlParser(state.candidate.notes)}
                          </p>
                          <div className="text-sm">
                            <b className="d-block">CV</b>
                            <aside style={thumbsContainer}>
                              {savedCandidateThumbs}
                            </aside>
                          </div>
                          <div className="ustorekit-common-above-margin-10">
                            <button
                              className="btn btn-danger"
                              onClick={() => onFindJobs()}
                            >
                              Find more jobs
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="tab-pane" id="upload_files">
                        <FilesUpload
                          getUploadedFiles={getUploadedFiles}
                          getDeleteSavedFile={getDeleteSavedFile}
                          savedFiles={savedFiles}
                          isUpdating={true}
                        />
                        <div className="ustorekit-common-above-margin-10">
                          <button
                            className="btn btn-danger"
                            onClick={handleUploadFiles}
                          >
                            Upload
                          </button>
                        </div>
                      </div>
                      <div className="tab-pane" id="history">
                        <div className="timeline timeline-inverse">
                          <ul>
                            {histories &&
                              histories.map((history) => (
                                <li key={history.id}>
                                  {Moment(history.created_at).format(
                                    "MM-DD-YYYY, HH:MM"
                                  )}
                                  {" - "}
                                  {history.content}
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <SlideDrawer show={drawerOpen} url={document} />
      {backdrop}
      <ToastContainer />
    </React.Fragment>
  );
}

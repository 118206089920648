export class commonVariables {
  static APPLICANT_STATUS = [
    { value: "0", label: "Not Applied Yet" },
    { value: "1", label: "Applied" },
    { value: "2", label: "CVAccepted" },
    { value: "3", label: "Interviewed" },
    { value: "4", label: "Offered" },
    { value: "5", label: "PassWin" },
    { value: "6", label: "PassLost" },
    { value: "7", label: "Failed" },
    { value: "8", label: "Canceled" },
    { value: "9", label: "Sent test document" },
    { value: "10", label: "FinishWin" },
  ];
  static GENDER_OPTIONS = [
    { value: "M", label: "Male" },
    { value: "F", label: "Female" },
  ];

  static getGenderLabel = (gender) => {
    if (gender === "M") {
      return "Male";
    } else {
      return "Female";
    }
  };
}

import React, { useState, useEffect, useContext } from "react";
import { API } from "../common/api-service";
import AuthContext from "../common/AuthContext";
import { useHistory, useLocation } from "react-router-dom";
import { commonFunction } from "../common/common-functions";
import { Link } from "react-router-dom";
import Tooltip from "../common/Tooltip";
import ReactHtmlParser from "react-html-parser";
import FindCandidatesBox from "../applicants/FindCandidatesBox";
import { PDFDownloadLink } from "@react-pdf/renderer";
import JobDescription from "./JobDescription";
import SlideDrawer from "../SlideDrawer/SlideDrawer";
import Backdrop from "../SlideDrawer/Backdrop";

// For react-dropzone
const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "relative",
  width: "auto",
  height: "100%",
};

const thumbName = {
  width: 100,
};

export default function JobDetail(props) {
  const history = useHistory();
  const location = useLocation();
  const initialState = {
    id: "",
    title: "",
    slug: "",
    location: "",
    salary: "",
    numofvacancies: "",
    level: "",
    reward: "",
    description: "",
    otherinfos: "",
    tags: "",
    company: "",
    active: false,
    notes: "",
  };
  const [state, setState] = useState(initialState);

  const { authState } = useContext(AuthContext);
  const token = authState.token.slice(1, -1);

  const [selectedCompany, setSelectedCompany] = useState({});
  const [savedFiles, setSavedFiles] = useState([]);
  const [locationTags, setLocationTags] = useState([]);
  const [levelTags, setLevelTags] = useState([]);
  const [appliedCandidates, setAppliedCandidates] = useState([]);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [document, setDocument] = useState("");

  const drawerToggleClickHandler = () => {
    setDrawerOpen(!drawerOpen);
  };

  const backdropClickHandler = () => {
    setDrawerOpen(false);
  };

  let backdrop;
  if (drawerOpen) {
    backdrop = <Backdrop close={() => backdropClickHandler()} />;
  }

  const handleFileClick = (preview) => {
    setDocument(preview);
    drawerToggleClickHandler();
  };

  const [hasFindCandidates, setHasFindCandidates] = useState(
    props.hasFindCandidates
  );

  const savedThumbs =
    savedFiles &&
    savedFiles.map((file, i) => {
      return (
        <div key={file.name}>
          <div style={thumb}>
            <div style={thumbInner}>
              <a
                href="javascript:void(0)"
                onClick={() => handleFileClick(file.preview)}
              >
                <img src={file.preview} style={img} />
              </a>
            </div>
          </div>
          <div style={thumbName}>
            <a
              href="javascript:void(0)"
              onClick={() => handleFileClick(file.preview)}
            >
              {file.name}
            </a>
          </div>
          <div style={thumbName}>
            <a href={file.preview} rel="noopener noreferrer" target="_blank">
              Download
            </a>
          </div>
        </div>
      );
    });

  const displayLocationTags =
    locationTags &&
    locationTags.map((tag, i) => {
      return (
        <button
          className="btn btn-info btn-xs ustorekit-common-margin-10-right"
          key={tag.text}
        >
          {tag.text}
        </button>
      );
    });

  const displayLevelTags =
    levelTags &&
    levelTags.map((tag, i) => {
      return (
        <button
          className="btn btn-info btn-xs ustorekit-common-margin-10-right"
          key={tag.text}
        >
          {tag.text}
        </button>
      );
    });

  const deleteItem = () => {
    if (location.state) {
      if (location.state.id) {
        if (window.confirm("Are you sure you wish to delete this item?"))
          API.updateItem(
            "job_active",
            location.state.id,
            { active: false },
            token
          )
            .then((resp) => {
              setState({ ...state, active: resp.active });
            })
            .catch((error) => console.log(error));
      }
    }
  };

  const onUpdate = () => {
    if (location.state) {
      if (location.state.id) {
        history.push({
          pathname: "/jobs/create/",
          state: { id: location.state.id },
        });
      }
    }
  };

  const onApply = () => {
    if (location.state) {
      if (location.state.id || state.id) {
        var jobid = location.state.id || state.id;
        history.push({
          pathname: "/candidates/create/",
          state: { jobid: jobid },
        });
      }
    }
  };

  // const onFindCandidates = () => {
  //   if (location.state) {
  //     if (location.state.id || state.id) {
  //       var jobid = location.state.id || state.id;
  //       history.push({
  //         pathname: "/applicants/find_candidates/",
  //         state: { id: jobid },
  //       });
  //     }
  //   }
  // };

  const onBack = () => {
    if (location.state) {
      if (location.state.jobid) {
        history.push({
          pathname: "/jobs/detail/",
          state: { id: location.state.jobid },
        });
      } else {
        history.push("/jobs/");
      }
    } else {
      history.push("/jobs/");
    }
  };

  const getAppliedCandidatesByJob = (job_id) => {
    var link =
      "candidates/get_applied_candidates_by_job/" + job_id.toString() + "/";
    API.getItems(link, token).then((resp3) => {
      var candidatesArr = resp3.candidates;
      var result = [];
      for (var m = 0; m < candidatesArr.length; m++) {
        result.push({
          id: candidatesArr[m].id,
          name: candidatesArr[m].name,
        });
      }
      setAppliedCandidates(result);
    });
  };

  useEffect(() => {
    if (location.state) {
      if (location.state.hasFindCandidates) {
        setHasFindCandidates(true);
      }
      if (location.state.id) {
        API.getItem("jobs", location.state.id, token)
          .then((resp) => {
            setState({
              ...state,
              id: resp.id,
              title: resp.title,
              slug: resp.slug,
              location: resp.location,
              salary: resp.salary,
              numofvacancies: resp.numofvacancies,
              level: resp.level,
              reward: resp.reward,
              description: resp.description,
              otherinfos: resp.otherinfos,
              tags: resp.tags,
              company: resp.company,
              active: resp.active,
              notes: resp.notes,
            });
            setLocationTags(commonFunction.splitStrToTags(resp.location));
            setLevelTags(commonFunction.splitStrToTags(resp.level));

            API.getItem("companies", resp.company, token).then((resp1) => {
              setSelectedCompany(resp1);
              // setcompanyLocationTags(
              //   commonFunction.splitStrToTags(resp1.location)
              // );
            });
            var link = "jobfiles/" + resp.id.toString() + "/";
            API.getItems(link, token).then((resp2) => {
              var old_files = resp2.files;
              var new_files = [];
              for (var i = 0, l = old_files.length; i < l; i++) {
                new_files.push({
                  id: old_files[i].id,
                  job: old_files[i].job,
                  name: old_files[i].name,
                  size: old_files[i].size,
                  lastModifiedDate: old_files[i].lastModifiedDate,
                  preview: old_files[i].file,
                });
              }
              setSavedFiles(new_files);
            });

            // Get applied cadidates
            getAppliedCandidatesByJob(resp.id);
          })
          .catch((error) => console.log(error));
      }
    }
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Job Detail</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Job Detail</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          <div className="card">
            <div className="card-header">
              <div className="row">
                <div className="col-md-6">
                  <h3 className="card-title">
                    {state.id} - {state.title}
                  </h3>
                </div>
                <div
                  // className="col-md-6 text-center"
                  className="col-md-6"
                  style={{ textAlign: "right" }}
                >
                  <button
                    className="btn btn-sm btn-primary ustorekit-common-margin-10-right"
                    onClick={() => onUpdate()}
                  >
                    Update
                  </button>
                  <button
                    className="btn btn-sm btn-danger ustorekit-common-margin-10-right"
                    onClick={deleteItem}
                  >
                    Delete
                  </button>
                  <button
                    className="btn btn-sm btn-default ustorekit-common-margin-10-right"
                    onClick={onBack}
                  >
                    Back
                  </button>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-12 col-md-12 col-lg-8 order-2 order-md-1">
                  <div className="row">
                    <div className="col-12 col-sm-4">
                      <div className="info-box bg-light">
                        <div className="info-box-content">
                          <span className="info-box-text text-center text-muted">
                            Salary
                          </span>
                          <span className="info-box-number text-center text-muted mb-0">
                            {state.salary}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-4">
                      <div className="info-box bg-light">
                        <div className="info-box-content">
                          <span className="info-box-text text-center text-muted">
                            Number of vacancies
                          </span>
                          <span className="info-box-number text-center text-muted mb-0">
                            {state.numofvacancies}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-4">
                      <div className="info-box bg-light">
                        <div className="info-box-content">
                          <span className="info-box-text text-center text-muted">
                            Reward
                          </span>
                          <span className="info-box-number text-center text-muted mb-0">
                            {state.reward}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <h5>Informations</h5>
                      <div className="text-muted">
                        <div className="text-sm">
                          <b className="d-block">Company</b>
                          <Tooltip
                            text={ReactHtmlParser(selectedCompany.description)}
                          >
                            <Link
                              to={{
                                pathname: "/companies/detail/",
                                state: { id: selectedCompany.id },
                              }}
                            >
                              {selectedCompany.name}
                            </Link>
                          </Tooltip>
                        </div>
                        <p className="text-sm">
                          <b className="d-block">Location</b>
                          {displayLocationTags}
                        </p>
                        <p className="text-sm">
                          <b className="d-block">Level</b>
                          {displayLevelTags}
                        </p>
                        <p className="text-sm">
                          <b className="d-block">Is active?</b>
                          {state.active ? "Yes" : "No"}
                        </p>
                        <p className="text-sm">
                          <b className="d-block">Description</b>
                          {ReactHtmlParser(state.description)}
                        </p>
                        <p className="text-sm">
                          <b className="d-block">Other Infomations</b>
                          {ReactHtmlParser(state.otherinfos)}
                        </p>
                        <p className="text-sm">
                          <b className="d-block">Notes</b>
                          {ReactHtmlParser(state.notes)}
                        </p>
                        <div className="text-sm">
                          <b className="d-block">Job description</b>
                          <aside style={thumbsContainer}>{savedThumbs}</aside>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-12 col-lg-4 order-1 order-md-2">
                  <h3 className="text-primary">
                    <i className="fas fa-paint-brush"></i> Applied candidates
                  </h3>
                  <ul className="list-unstyled">
                    {appliedCandidates &&
                      appliedCandidates.map((appliedcandidate) => (
                        <li key={appliedcandidate.id}>
                          <Link
                            to={{
                              pathname: `/candidates/detail/`,
                              state: { id: appliedcandidate.id },
                            }}
                            className="btn-link text-secondary"
                          >
                            <i className="far fa-hand-point-right"></i>
                            {"   "}
                            {appliedcandidate.id}
                            {" - "}
                            {appliedcandidate.name}
                          </Link>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="card-footer">
              {/* <button
                className="btn btn-sm btn-primary ustorekit-common-margin-10-right"
                onClick={() => onGenerateJD()}
              >
                Generate JD
              </button> */}
              <PDFDownloadLink
                document={<JobDescription job={state} />}
                fileName={state.title + ".pdf"}
              >
                {({ blob, url, loading, error }) =>
                  loading ? (
                    <button className="btn btn-sm btn-primary ustorekit-common-margin-10-right">
                      Loading JD...
                    </button>
                  ) : (
                    <button className="btn btn-sm btn-primary ustorekit-common-margin-10-right">
                      Download JD
                    </button>
                  )
                }
              </PDFDownloadLink>
              {/* <Link target="_blank"
                    to={{
                      pathname: "/candidates/create/",
                      state: { jobid: location.state.id }
                    }}
                    className="btn btn-sm btn-success ustorekit-common-margin-10-right"
                  >
                    Apply new candidate
                  </Link> */}
              <button
                className="btn btn-sm btn-success ustorekit-common-margin-10-right"
                onClick={onApply}
              >
                Apply new candidate
              </button>
              {/* <Link
                    target="_blank"
                    to={{
                      pathname: "/applicants/find_candidates/",
                      state: { id: state.id },
                    }}
                    className="btn btn-sm btn-warning ustorekit-common-margin-10-right"
                  >
                    Find candidates
                  </Link> */}
              <button
                className="btn btn-sm btn-warning ustorekit-common-margin-10-right"
                onClick={() => {
                  setHasFindCandidates(true);
                }}
              >
                Find candidates
              </button>
              <button
                className="btn btn-sm btn-primary ustorekit-common-margin-10-right"
                onClick={() => onUpdate()}
              >
                Update
              </button>
              <button
                className="btn btn-sm btn-danger ustorekit-common-margin-10-right"
                onClick={deleteItem}
              >
                Delete
              </button>
              <button
                className="btn btn-sm btn-default ustorekit-common-margin-10-right"
                onClick={onBack}
              >
                Back
              </button>
            </div>
          </div>
          {hasFindCandidates ? (
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Candidates Box</h3>
                <div className="card-tools">
                  <button
                    type="button"
                    className="btn btn-tool"
                    data-card-widget="collapse"
                    data-toggle="tooltip"
                    title="Collapse"
                  >
                    <i className="fas fa-minus"></i>
                  </button>
                  <button
                    type="button"
                    className="btn btn-tool"
                    data-card-widget="remove"
                    data-toggle="tooltip"
                    title="Remove"
                    onClick={() => {
                      setHasFindCandidates(!hasFindCandidates);
                    }}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
              <div className="card-body">
                <div className="relativeWrapper">
                  <div className="absoluteWrapper"></div>
                  <FindCandidatesBox
                    job_id={state.id}
                    refreshAppliedCandidates={() =>
                      getAppliedCandidatesByJob(state.id)
                    }
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {/* {openCard ? (
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Documents</h3>
                <div className="card-tools">
                  <button
                    type="button"
                    className="btn btn-tool"
                    data-card-widget="collapse"
                    data-toggle="tooltip"
                    title="Collapse"
                  >
                    <i className="fas fa-minus"></i>
                  </button>
                </div>
              </div>
              <div className="card-body">
                <Documents url={document} />
              </div>
            </div>
          ) : (
            ""
          )} */}

          {/* <div className="card">
            <div className="card-header">
              <h3 className="card-title">Test</h3>
              <div className="card-tools">
                <button
                  type="button"
                  className="btn btn-tool"
                  data-card-widget="collapse"
                  data-toggle="tooltip"
                  title="Collapse"
                >
                  <i className="fas fa-minus"></i>
                </button>
              </div>
            </div>
            <div className="card-body">
              <PDFViewer>
                <JobDescription job={state} />
              </PDFViewer>
            </div>
          </div> */}
        </section>
      </div>
      <SlideDrawer show={drawerOpen} url={document} />
      {backdrop}
    </React.Fragment>
  );
}

export class commonFunction {
  static range = (start, end) => [...Array(end - start + 1)].map((_, i) => start + i);

  static slugify(str) {
    str = str.replace(/^\s+|\s+$/g, ""); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from =
      "àáãạảäăắằẵẳặâấầẩẫậđèéẽẻẹëêếềểễệìíĩỉịïîòóỏõọöôốồổỗộơớờởợỡùúủũụưứừửữựüûýỳỷỹỵñç·/_,:;";
    var to =
      "aaaaaaaaaaaaaaaaaadeeeeeeeeeeeeiiiiiiioooooooooooooooooouuuuuuuuuuuuuyyyyync------";

    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
    }

    str = str
      .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
      .replace(/\s+/g, "-") // collapse whitespace and replace by -
      .replace(/-+/g, "-"); // collapse dashes

    return str;
  }

  static connectStr(arr) {
    var str = "";
    for (var i = 0, l = arr.length; i < l; i++) {
      str = str + arr[i].text + "; ";
    }
    str = str.slice(0, -2);
    return str;
  }

  static splitStrToTags(str) {
    var arr = str.split("; ");
    var tags = [];
    for (var i = 0, l = arr.length; i < l; i++) {
      tags.push({ id: arr[i], text: arr[i] });
    }
    return tags;
  }

  static connectToList(arr) {
    var list = [];
    for (var i = 0, l = arr.length; i < l; i++) {
      list.push(arr[i].text);
    }
    return list;
  }

  static splitListToTags(arr) {
    var tags = [];
    for (var i = 0, l = arr.length; i < l; i++) {
      tags.push({ id: arr[i], text: arr[i] });
    }
    return tags;
  }
}

import { API } from "./api-service";

export class dataCommonFunction {
  static handleActionBox(item, rows, action, indexes, token) {
    switch (action) {
      case 1:
        indexes &&
          indexes.map((index) => {
            API.updateItem(item, rows[index].id, { active: false }, token)
              .then((resp) => {
                if (resp.active) {
                  rows[index]["active"] = "Yes";
                } else {
                  rows[index]["active"] = "No";
                }
                return rows;
              })
              .catch((error) => console.log(error));
          });
        return rows;

        break;
      case 2:
        indexes &&
          indexes.map((index) => {
            API.updateItem(item, rows[index].id, { active: true }, token)
              .then((resp) => {
                if (resp.active) {
                  rows[index]["active"] = "Yes";
                } else {
                  rows[index]["active"] = "No";
                }
                return rows;
              })
              .catch((error) => console.log(error));
          });
        return rows;
        break;
      default:
        return rows;
        break;
    }
  }

  static createJobRow(row, cols, token) {
    var validrow = {};
    cols &&
      cols.forEach((col) => {
        switch (col.key) {
          case "company":
            API.getItem("companies", row[col.key], token).then((resp) => {
              validrow[col.key] = resp.name;
            });
            break;
          case "active":
            if (row[col.key]) {
              validrow[col.key] = "Yes";
            } else {
              validrow[col.key] = "No";
            }
            break;
          default:
            validrow[col.key] = row[col.key];
            validrow["id"] = row["id"];
        }
      });
    return validrow;
  }

  static createSelectOption(options_db) {
    const options = [];
    options_db &&
      options_db.map((t) => {
        options.push({ value: t.id, label: t.name });
      });

    return options;
  }

  static createOption(item, body, token, list) {
    setTimeout(() => {
      return API.createItem(item, body, token)
        .then((resp) => {
          let option = { value: resp.result.id, label: resp.result.name };
          list = list.push(option);
          return list;
        })
        .catch((error) => console.log(error));
    }, 1000);
  }
}

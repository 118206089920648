import React, { useState, useEffect, useContext } from "react";
import ReactDataGrid from "react-data-grid";
import { Toolbar, Data, Filters } from "react-data-grid-addons";
import "../common/custom_css.css";
import AuthContext from "../common/AuthContext";
import { API } from "../common/api-service";
import { Link, useHistory } from "react-router-dom";
import { commonVariables } from "../common/common-variables";
import Moment from "moment";
import ArticleIcon from "@mui/icons-material/Article";
import WorkIcon from '@mui/icons-material/Work';
import PeopleIcon from '@mui/icons-material/People';
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";

export default function Applicants() {
  const history = useHistory();
  const { authState } = useContext(AuthContext);
  const token = authState.token.slice(1, -1);

  const renderTags = (tag) => {
    return (
      <button className="btn btn-secondary btn-xs tags-margin" key={tag.text}>
        {tag.text}
      </button>
    );
  };

  const deleteRow = (id) => {
    if (window.confirm("Are you sure you wish to delete this item?"))
      API.updateItem("super_applicants", id, { active: false }, token)
        // API.deleteItem("candidates", id, token)
        .then((resp) => {
          API.getItems("super_applicants/", token)
            .then((resp2) => {
              const rows = createRowsList(resp2, columns);
              setState({
                ...state,
                columns: columns,
                rows: rows,
              });
            })
            .catch((error) => console.log(error));
          history.push("/applicants/");
        })
        .catch((error) => console.log(error));
  };

  const activeRow = (id) => {
    if (window.confirm("Are you sure you wish to active this item?"))
      API.updateItem("super_applicants", id, { active: true }, token)
        // API.deleteItem("candidates", id, token)
        .then((resp) => {
          API.getItems("super_applicants/", token)
            .then((resp2) => {
              const rows = createRowsList(resp2, columns);
              setState({
                ...state,
                columns: columns,
                rows: rows,
              });
            })
            .catch((error) => console.log(error));
          history.push("/applicants/");
        })
        .catch((error) => console.log(error));
  };

  const columns = React.useMemo(
    () => [
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        width: 150,
        cellClassName: "actions",
        getActions: (params) => {
          return [
            <GridActionsCellItem
              icon={<ArticleIcon />}
              label="Detail"
              className="textPrimary"
              component={Link}
              to={{
                pathname: `/applicants/detail/`,
                state: { id: params.id },
              }}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<WorkIcon />}
              label="Find Jobs"
              className="textPrimary"
              component={Link}
              to={{
                pathname: `/candidates/detail/`,
                state: { id: params.row.candidateid, hasFindJobs: true },
              }}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<PeopleIcon />}
              label="Find Candidates"
              className="textPrimary"
              component={Link}
              to={{
                pathname: `/jobs/detail/`,
                state: { id: params.row.jobid, hasFindCandidates: true },
              }}
              color="inherit"
            />,
          ];
        },
      },
      { field: "id", headerName: "ID", width: 50 },
      { field: "jobid", headerName: "Job ID", width: 80 },
      { field: "jobtitle", headerName: "Job Title", width: 250 },
      { field: "candidateid", headerName: "Candidate ID", width: 100 },
      { field: "candidatename", headerName: "Candidate Name", width: 200 },
      { field: "status", headerName: "Status", width: 80 },
      { field: "interview_date", headerName: "Interview Date", width: 100 },
      { field: "is_paid", headerName: "Is paid?", width: 100 },
    ],
    [deleteRow, activeRow]
  );

  const initialState = {
    columns: [],
    rows: [],
  };

  const [state, setState] = useState(initialState);

  function createRowsList(rows, cols) {
    let validrows = [];
    rows &&
      rows.forEach((row) => {
        let validrow = {};
        validrow["id"] = row.id;
        validrow["jobid"] = row.job.id;
        validrow["jobtitle"] = row.job.title;
        validrow["candidateid"] = row.candidate.id;
        validrow["candidatename"] = row.candidate.name;
        var option = commonVariables.APPLICANT_STATUS.find((element) => {
          return element.value === row["status"];
        });
        validrow["status"] = option.label;
        if (row["is_paid"]) {
          validrow["is_paid"] = "✔";
        } else {
          validrow["is_paid"] = "×";
        }
        if (row["interview_date"] != null) {
          validrow["interview_date"] = Moment(row.interview_date).format(
            "MM-DD-YYYY, HH:MM"
          );
        } else {
          validrow["interview_date"] = "";
        }
        validrows.push(validrow);
      });
    return validrows;
  }

  useEffect(() => {
    API.getItems("super_applicants", token)
      .then((resp) => {
        const rows = createRowsList(resp, columns);
        setState({
          ...state,
          columns: columns,
          rows: rows,
        });
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <React.Fragment>
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Applicants</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Applicants</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          <div className="card card-default">
            <div className="card-body">
              <div className="relativeWrapper">
                <div className="absoluteWrapper"></div>
                <DataGrid rows={state.rows} columns={columns} />
              </div>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
}
